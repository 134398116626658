import { Box, Button, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { clientApi } from 'api/clientApi'
import FormErrorMessage from 'components/common/formErrorMessage/formErrorMessage'
import AppPreloader from 'components/common/preloaders/appPreloader'
import { MyDialog } from 'components/UI/Dialog'
import { userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'
import { appFormShopRequestDataSelector } from 'redux/reducers/client/allState/clientSelectors'
import { getErrorMessage } from 'utils/getErrorMessage'

import TranchesModal from './TranchesModal'
import TranchesTable from './TranchesTable'

const TranchesPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const {
    watch,
    control,
    formState: { errors }
  } = useForm<any>({
    mode: 'onChange'
  })

  const { appFormFlowData } = useSelector(userFlowSelector)
  const shopRequestData = useSelector(appFormShopRequestDataSelector)

  const [requestProcessing, setRequestProcessing] = useState(false)
  const [showTranche, setShowTranche] = useState<boolean>(false)
  const [tranchesList, setTranchesList] = useState<any>([])
  const [summaryAmount, setSummaryAmount] = useState<number>(0)
  const [minTranche, setMinTranche] = useState<number>(0)
  const [maxTranche, setMaxTranche] = useState<number>(0)

  const dateInterestStartWatch = watch('date_interest_start')

  const filterTranchesList = useMemo(() => {
    if (tranchesList.length !== 0) {
      return tranchesList.sort((a: any, b: any) => {
        if (moment(a.date).toDate() < moment(b.date).toDate()) {
          return -1
        }

        if (moment(a.date).toDate() > moment(b.date).toDate()) {
          return 1
        }

        return 0
      })
    }
    return []
  }, [tranchesList])

  const summary = {
    amount: 0
  }

  const handlePrev = useCallback(() => {
    if (appFormFlowData) {
      dispatch(updateAppFormUserFlowThunk({ step: appFormFlowData.flow_data.step - 1 }))
    }
  }, [appFormFlowData, dispatch])

  const sendTranches = useCallback(async () => {
    if (appFormFlowData?.flow_data?.id) {
      return await clientApi.applicationForm.postTranchesApplication(appFormFlowData?.flow_data?.id, tranchesList)
        .catch((err) => {
          dispatch(
            setClientToastMessage({
              type: 'error',
              message: getErrorMessage(err?.response?.data)
            })
          )
        })
    }
  }, [appFormFlowData?.flow_data?.id, tranchesList, dispatch])

  const handleNext = async () => {
    const flow_data = appFormFlowData?.flow_data
    const res = await sendTranches()
    if (flow_data && ((res?.status === 200) || (res?.status === 201))) {
      if (dateInterestStartWatch) {
        clientApi.applicationForm.changeDateInterestStart(appId, moment(dateInterestStartWatch).format('YYYY-MM-DD'))
      }
      dispatch(updateAppFormUserFlowThunk({ step: flow_data.step + 1 }))
    }
  }

  const handleShowTranche = () => {
    setShowTranche(true)
  }

  const handleCloseTranche = () => {
    setShowTranche(false)
  }

  useEffect(() => {
    if (appFormFlowData?.flow_data?.id) {
      const getTranches = async () => {
        setRequestProcessing(true)
        const res = await clientApi.applicationForm.getTranchesApplication(appFormFlowData?.flow_data?.id)
          .finally(() => setRequestProcessing(false))
        if (res.status === 200) {
          setTranchesList(res.data)
        }
      }
      getTranches()
    }
  }, [appFormFlowData?.flow_data?.id])

  useEffect(() => {
    if (tranchesList?.length !== 0) {
      tranchesList?.forEach((tranche: any, index: number) => {
        if (index === 0) {
          setSummaryAmount(0)
        }
        setSummaryAmount(prev => prev + +tranche.amount)
      })
    } else {
      setSummaryAmount(0)
    }
  }, [tranchesList])

  useEffect(() => {
    if (shopRequestData) {
      setMinTranche(shopRequestData.credit_policy.min_tranches)
      setMaxTranche(shopRequestData.credit_policy.max_tranches)
    }
  }, [shopRequestData])

  if (requestProcessing) {
    return <Box mt={5} textAlign="center">
      <AppPreloader />
      {t('common.loading')}
    </Box>
  }

  // @ts-ignore
  const { flow_data: { id: appId } } = appFormFlowData

  return (
    <>
      <Box
        mb={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}
      >
        <Box sx={{ marginBottom: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%' }}>
          <Box sx={{ mt: 1 }}>
            {shopRequestData?.credit_policy?.interest_calculation_period === 'On a Specified Date' && (
              <>
                <Controller
                  control={control}
                  name={'date_interest_start'}
                  defaultValue={null}
                  rules={{
                    required: {
                      value: true,
                      message: t('formValidation.required')
                    }
                  }}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      label={t('client.applicationForm.tranches.table.head.dateInterestStart')}
                      value={value}
                      minDate={moment(new Date()).toDate()}
                      maxDate={moment(shopRequestData?.application?.loan_date_to).toDate()}
                      onChange={date => onChange(date)}
                      renderInput={params => <TextField fullWidth {...params} />}
                    />
                  )}
                />
                <FormErrorMessage errors={errors} name='date_interest_start' />
              </>
            )}
          </Box>
          {shopRequestData?.application?.loan_amount && (
            <Button
              sx={{ mt: 1 }}
              onClick={handleShowTranche}
              disabled={shopRequestData?.application?.loan_amount === summaryAmount}
              variant="contained"
            >
              {t('client.applicationForm.tranches.button.add')}
            </Button>
          )}
        </Box>
        {tranchesList?.length !== 0 ? (
          <>
            <TranchesTable tranchesList={filterTranchesList} setTranchesList={setTranchesList} summary={summary} />
            {shopRequestData?.application && (
              <>
                <Box
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    mt: 2,
                    '& > p': {
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontSize: '0.75rem'
                    }
                  }}
                >
                  <Typography>{t('client.applicationForm.tranches.theAmountShouldBeEqual')} {shopRequestData?.application?.loan_amount}</Typography>
                  <Typography>{t('client.applicationForm.tranches.min')} {minTranche}</Typography>
                  <Typography>{t('client.applicationForm.tranches.max')} {maxTranche}</Typography>
                </Box>

              </>
            )}
          </>
        ) : (
          <Box textAlign="center" sx={{ width: '100%' }}>
            {t('client.applicationForm.tranches.addTranchesToContinueFillingOutTheApplication')}
          </Box>
        )}

      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <Button onClick={handlePrev} variant="contained">
          {t('client.applicationForm.bankCard.buttonPrev')}
        </Button>
        <Button
          onClick={handleNext}
          variant="contained"
          disabled={((filterTranchesList?.length < minTranche) ||
            (filterTranchesList?.length > maxTranche)) ||
          (shopRequestData?.application?.loan_amount !== summaryAmount) ||
          (shopRequestData?.credit_policy?.interest_calculation_period === 'On a Specified Date' && !dateInterestStartWatch)}
        >
          {t('common.buttons.next')}
        </Button>
      </Box>
      {showTranche && (
        <MyDialog isOpen={showTranche} title={t('client.applicationForm.tranches.modal.title')} maxWidth={'xs'} onCloseClick={handleCloseTranche}>
          <TranchesModal
            handleClose={handleCloseTranche}
            applicationId={appId}
            tranchesList={filterTranchesList}
            setTranchesList={setTranchesList}
            shopRequestData={shopRequestData}
            summary={summary}
          />
        </MyDialog>
      )}
    </>
  )
}

export default TranchesPage
