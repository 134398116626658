const authActions = {
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_TOKEN: 'SET_TOKEN',
  SET_USER: 'SET_USER',
  SET_SETTINGS: 'SET_SETTINGS',
  SET_APP_FORM_USER_FLOW: 'SET_APP_FORM_USER_FLOW',
  UPDATE_APP_FORM_USER_FLOW: 'UPDATE_APP_FORM_USER_FLOW',
  SET_USER_FLOW_LOAD_STATUS: 'SET_USER_FLOW_LOAD_STATUS',

  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',

  HANDLE_ERROR: 'HANDLE_ERROR',
  SET_AUTH_ERRORS: 'SET_AUTH_ERRORS'
} as const

export default authActions
