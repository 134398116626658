import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import IconGoogle from 'assets/images/icons/google-icon.svg'
import { GOOGLE_ID } from 'config'
import { googleSignInThunk } from 'redux/reducers/auth/authThunks'
import { loadScript } from 'utils/loadScript'

import styles from './GoogleSignInButton.module.scss'

declare const google: any

interface IOAuthResponse {
  access_token: string;
}

export const GoogleSignInButton: React.FC = () => {
  const dispatch = useDispatch()

  const handleCredentialResponse = useCallback((response: IOAuthResponse) => {
    dispatch(googleSignInThunk(response.access_token))
  }, [dispatch])

  const handleGoogleSignIn = useCallback(() => {
    const tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: GOOGLE_ID,
      prompt: 'consent',
      scope: 'https://www.googleapis.com/auth/userinfo.email',
      callback: handleCredentialResponse
    })

    tokenClient.requestAccessToken()
  }, [handleCredentialResponse])

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'

    loadScript(src)

    return () => {
      const script = document.querySelector(`script[src="${src}"]`)
      if (script) document.body.removeChild(script)
    }
  }, [])

  return <div
    className={styles.googleBtn}
    role="button"
    tabIndex={0}
    onClick={handleGoogleSignIn}
  >
    <img src={IconGoogle} alt="Google icon" className={styles.icon} />
    <span className={styles.title}>Google</span>
  </div>
}
