import {
  FormControl,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
  Tooltip,
  SxProps
} from '@mui/material'
import Zoom from '@mui/material/Zoom'
import React, { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

import { ICustomInput } from 'features/formConstructor/types'

import iconWarning from '../../../../../assets/images/icons/warning.png'

interface IProps {
  input: ICustomInput
  styles?: SxProps
  cError?: FieldError | undefined
}

export type TCustomTextInputProps = IProps & OutlinedInputProps

export const CustomTextInput = forwardRef<HTMLDivElement, TCustomTextInputProps>(
  ({ input, styles, cError, ...props }, ref) => {
    const { type, question } = input

    return (
      <FormControl fullWidth sx={styles} error={!!cError}>
        <FormLabel>{question}</FormLabel>
        <OutlinedInput
          ref={ref}
          type={type}
          {...props}
          endAdornment={
            cError && <Tooltip
                title={cError.message ?? ''}
                arrow
                TransitionComponent={Zoom}
                enterTouchDelay={0}
              >
                <span style={{ cursor: 'help' }}>
                  <img
                    src={iconWarning}
                    alt="Warning icon"
                    style={{ width: '20px', pointerEvents: 'none' }}
                  />
                </span>
              </Tooltip>
          }
        />
      </FormControl>
    )
  }
)

CustomTextInput.displayName = 'CustomTextInput'
