import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'

import { sendActivationEmailThunk } from 'redux/reducers/auth/authThunks'
import { translateType } from 'translations/types'

interface IProps {
  email: string
  t: translateType
  sendActivationEmailThunk: (email: string) => any
}

const SignUpVerification: React.FC<IProps> = ({ t, sendActivationEmailThunk, email }) => {
  const onSubmit = () => sendActivationEmailThunk(email)

  return (
    <>
      <Typography variant="h4" align="center" pt={4} mb={2}>{t('homePage.signUpVerification.title')}</Typography>
      <Typography variant="h5" align="center" mb={4}>
        {t('homePage.signUpVerification.checkEmail')}
      </Typography>
      <Typography align="center" mb={2}>{t('homePage.signUpVerification.resendEmail')}</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }} mb={4}>
        <Button variant="contained" onClick={onSubmit}>{t('homePage.signUpVerification.button')}</Button>
      </Box>
    </>
  )
}

export default connect(null, { sendActivationEmailThunk })(SignUpVerification)
