import classNames from 'classnames'
import React from 'react'

import styles from './Overlay.module.scss'

export interface IOverlayProps {
  className?: string
  isVisible?: boolean
  onClick?: () => void
}

export const Overlay: React.FC<IOverlayProps> = ({
  className,
  isVisible,
  children,
  onClick
}) => (
  <div className={classNames(
    styles.overlay,
    {
      [styles.overlayIsVisible]: isVisible
    },
    className
  )}>
    <div
      className={styles.backdrop}
      role="presentation"
      onClick={onClick}
    />
    { children }
  </div>
)
