import { createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'

import { axiosV2InstancePrivate } from 'api/api'
import { setLenderToastMessage } from 'redux/reducers/lender/allState/lenderReducer'
import { IScoreCardQuery, ScoringQueryModalDataType, ThunkQueryArgType } from 'redux/reducers/lender/scoring/types'

export const postQuery = createAsyncThunk(
  'lender/scoring/postQuery',
  async (query: ScoringQueryModalDataType, thunkAPI) => {
    try {
      const res = await axiosV2InstancePrivate.post<IScoreCardQuery>('lender/scorecard/queries/', query)

      if (!res.data) {
        thunkAPI.dispatch(setLenderToastMessage(i18next.t('lender.decisionEngine.rulePage.toast.postQueryNoData')))
        throw new Error()
      }

      return res.data
    } catch (e: any) {
      let message = e.response?.data?.non_field_errors
      if (!message) message = e.message

      if (message) {
        if (message[0] === 'The fields mapping_block, mapping_input, credit_policy must make a unique set.') {
          message = i18next.t('lender.decisionEngine.rulePage.toast.queryIsExists')
        }

        thunkAPI.dispatch(setLenderToastMessage({ type: 'error', message }))
      }
      thunkAPI.rejectWithValue(message)
    }
  })
