import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { fetchBankIdApplicationId } from 'components/api/fetchBankIdApplicationId'
import { useSearchParams } from 'components/common/hooks/useSearchParams'
import { setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'

const BankIdCallbackPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const urlParams = useSearchParams()

  const code = urlParams('code')
  const stateUUID = urlParams('state')

  useEffect(() => {
    if (!code || !stateUUID) {
      history.push('/')
    }

    fetchBankIdApplicationId(code, stateUUID).then((r) => {
      if (r.error) {
        dispatch(setClientToastMessage({
          message: r.error,
          type: 'error'
        }))

        history.push('/')
      }

      if (r.appId) {
        // toast param syntax `message:type`
        history.push('/client/application/?toast=Success:success')
      }
    })
  }, [code, dispatch, history, stateUUID])

  return (
    <div>
    </div>
  )
}

export default BankIdCallbackPage
