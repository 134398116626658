import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'

import FormErrorMessage from 'components/common/formErrorMessage/formErrorMessage'
import { recoverPasswordGETRequestThunk } from 'redux/reducers/services/servicesThunks'
import { translateType } from 'translations/types'

import AuthTitle from '../ui/authTitle'

interface IProps {
  recoverPasswordRequestThunk: (email: string) => void
  recoverHandler: () => void
  t: translateType
}

interface IForm {
  email: string
}

const getValidation = (t: translateType) => {
  return {
    email: {
      required: t('formValidation.required'),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: t('formValidation.pattern')
      }
    }
  }
}

const RecoverPassword: React.FC<IProps> = ({ recoverPasswordRequestThunk, recoverHandler, t }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<IForm>({ mode: 'onChange' })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [email, setEmail] = useState('')
  const validation = getValidation(t)
  const handleResend = () => {
    setIsSubmitted(false)
  }
  const onSubmit = (data: IForm) => {
    setEmail(data.email)
    recoverPasswordRequestThunk(data.email)
    setIsSubmitted(true)
  }
  return (
    <>
      <AuthTitle password title={t('homePage.signUp.recovery')}/>
      {isSubmitted
        ? <>
          <Typography variant="h5" mb={12} align="center">
            {t('homePage.signUp.instructions')} <b>{email}</b>{t('homePage.signUp.resending')}
          </Typography>
          <Box mb={4}>
            <Button
              type="button"
              size="large"
              fullWidth
              variant="contained"
              onClick={handleResend}
            >
              {t('homePage.signUp.sendAgain')}
            </Button>
          </Box>
        </>
        : <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={8}>
            <Typography variant="h5" mb={2}>{t('homePage.signUp.emailUsing')}</Typography>
            <TextField
              label={t('homePage.signUp.labelEmail')}
              variant="standard"
              fullWidth
              {...register('email', { ...validation.email })}
              type="email"
            />
            <FormErrorMessage errors={errors} name="email"/>
          </Box>
          <Box mb={4}>
            <Button size="large" variant="contained" fullWidth type="submit">{t('common.buttons.save')}</Button>
          </Box>
        </form>
      }
      <Box sx={{ justifyContent: 'center', display: 'flex' }} mb={4}>
        <Button onClick={recoverHandler}>{t('homePage.signUp.loginPage')}</Button>
      </Box>
    </>
  )
}

export default connect(null, { recoverPasswordRequestThunk: recoverPasswordGETRequestThunk })(RecoverPassword)
