import { Paper, Table, TableContainer } from '@mui/material'
import React, { FC } from 'react'

import TranchesTableBody from './TranchesTableBody'
import TranchesTableHead from './TranchesTableHead'

interface IProps {
  tranchesList: any
  setTranchesList: (prev: any) => any
  summary: {
    amount: number
  }
}

const TranchesTable: FC<IProps> = ({ tranchesList, setTranchesList, summary }) => {
  return (
    <TableContainer component={Paper} elevation={5} sx={{ borderRadius: 4 }}>
      <Table sx={{ minWidth: 650 }}>
        <TranchesTableHead />
        <TranchesTableBody tranchesList={tranchesList} setTranchesList={setTranchesList} summary={summary} />
      </Table>
    </TableContainer>
  )
}
export default TranchesTable
