import { createAsyncThunk } from '@reduxjs/toolkit'

import { axiosInstancePrivate } from 'api/api'
import { IProductShippingStatus } from 'redux/reducers/client/productShipping/types'

export const getShippingStatuses = createAsyncThunk(
  'getShippingStatus',
  async (applicationId: number, thunkAPI) => {
    try {
      const res = await axiosInstancePrivate.get<IProductShippingStatus[]>(`client/applications/${applicationId}/shipping-status/`)

      return res.data
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.message)
    }
  })
