import i18next from 'i18next'

export type TimeUnitType =
  'sec' |
  'min' |
  'hour' |
  'day' |
  'week' |
  'month' |
  'year'

export default class TimeUnit {
  readonly id: string
  readonly type: TimeUnitType
  private readonly toDaysMultiplier: number
  readonly labelOne: string
  readonly labelMultiple: string

  constructor (
    id: string,
    type: TimeUnitType,
    toDaysMultiplier: number,
    labelOne: string,
    labelMultiple: string
  ) {
    this.id = id
    this.type = type
    this.toDaysMultiplier = toDaysMultiplier
    this.labelOne = labelOne
    this.labelMultiple = labelMultiple
  }

  toDays (value: number) {
    return value * this.toDaysMultiplier
  }

  toString (value: number, toLowerCase = false) {
    let timeStr = this.labelMultiple
    if (value === 1) timeStr = this.labelOne

    if (toLowerCase) return `${value} ${timeStr.toLowerCase()}`
    else return `${value} ${timeStr}`
  }

  static Sec (id: string) {
    return new TimeUnit(
      id,
      'sec',
      1 / (3600 * 24),
      i18next.t('common.units.second'),
      i18next.t('common.units.seconds')
    )
  }

  static Min (id: string) {
    return new TimeUnit(
      id,
      'min',
      1 / (60 * 24),
      i18next.t('common.units.minute'),
      i18next.t('common.units.minutes')
    )
  }

  static Hour (id: string) {
    return new TimeUnit(
      id,
      'min',
      1 / 24,
      i18next.t('common.units.hour'),
      i18next.t('common.units.hours')
    )
  }

  static Day (id: string) {
    return new TimeUnit(
      id,
      'day',
      1,
      i18next.t('common.units.day'),
      i18next.t('common.units.days')
    )
  }

  static Week (id: string) {
    return new TimeUnit(
      id,
      'week',
      7,
      i18next.t('common.units.week'),
      i18next.t('common.units.weeks')
    )
  }

  static Month (id: string) {
    return new TimeUnit(
      id,
      'month',
      31,
      i18next.t('common.units.month'),
      i18next.t('common.units.months')
    )
  }

  static Year (id: string) {
    return new TimeUnit(
      id,
      'year',
      365,
      i18next.t('common.units.year'),
      i18next.t('common.units.years')
    )
  }
}
