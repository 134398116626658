import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TextInputModalConfirmActionType, TextInputModalSchema } from 'redux/reducers/common/textInputModal/types'

const initialState: TextInputModalSchema = {
  confirmAction: null,
  isActive: false,
  text: '',
  value: ''
}

const slice = createSlice({
  name: 'textInputModal',
  initialState,
  reducers: {
    open: (state, action: PayloadAction<{ confirmAction: TextInputModalConfirmActionType, text: string }>) => {
      state.isActive = true
      state.confirmAction = action.payload.confirmAction
      state.text = action.payload.text
      state.value = ''
    },
    close: (state, action: PayloadAction<undefined>) => {
      state.isActive = false
      state.confirmAction = null
      state.text = ''
      state.value = ''
    },
    setValue: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    }
  }
})

export const { actions: textInputModalActions } = slice
export const { reducer: textInputModalReducer } = slice
