import { ValidationRule } from 'react-hook-form'

import { TInputType } from '../types'

export const getPattern = (type: TInputType): ValidationRule<RegExp> | undefined => {
  switch (type) {
    case 'text':
      return {
        value: /[0-9а-яА-ЯёЁїЇіІa-zA-Z]{1,}/,
        message: 'Minimum length must be 1 characters'
      }
    case 'email':
      return {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Invalid email format'
      }

    case 'number':
      return {
        value:
          /^[0-9]*$/,
        message: 'Invalid number format'
      }
  }
}
