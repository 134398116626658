import { FormControl, FormLabel, SxProps } from '@mui/material'
import React, { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'

import { applicationFormTheme } from 'features/formConstructor/themes'
import { ICustomInput } from 'features/formConstructor/types'

interface IProps {
  input: ICustomInput
  styles?: SxProps
  cError?: FieldError | undefined
}

const inputTheme = applicationFormTheme.components?.MuiOutlinedInput?.styleOverrides?.root

export const CustomPhoneInput = forwardRef<HTMLDivElement, IProps & PhoneInputProps>(
  ({ cError, styles, input, ...props }, ref) => {
    const { question } = input
    return (
      <FormControl fullWidth sx={styles} error={!!cError}>
        <FormLabel>{question}</FormLabel>
        <PhoneInput
          {...props}
          inputProps={{ ref }}
          country={'ua'}
          specialLabel={''}
          inputStyle={{
            ...(inputTheme as Object),
            border: cError ? '1px solid red' : 'none',
            width: '100%'
          }}
        />
      </FormControl>
    )
  }
)

CustomPhoneInput.displayName = 'CustomPhoneInput'
