export const roundNumber = (num: string | number| undefined): string| undefined => {
  if (num === undefined) {
    return num
  }

  if (typeof num === 'number') {
    return num.toFixed(2)
  }
  return Number.parseFloat(num).toFixed(2)
}
