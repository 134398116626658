import { Box, Button, Container, Tab, Tabs, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import { clientApi } from 'api/clientApi'
import { LoadingOverlay } from 'components/common/LoadingOverlay/LoadingOverlay'
import ConstructorStep from 'features/formConstructor/components/ConstructorStep/ConstructorStep'
import { applicationFormTheme } from 'features/formConstructor/themes'
import { userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'
import { deleteUserFlow } from 'redux/reducers/client/allState/clientThunks'
import { confirmModalActions } from 'redux/reducers/common/confirmModal/confirmModalSlice'
import { constructorActions } from 'redux/reducers/constructors/formConstructor/state/actions'
import {
  clientApplicationDataSelector,
  constructorTemplateSelector,
  currentStepIdSelector,
  templateStepsSelector
} from 'redux/reducers/constructors/formConstructor/state/selectors'

interface IFormFields {
  [blockName: string | 'files']: {
    [inputName: string]: any
    __files?: {
      [key: string]: {
        elements: {
          inputId: string
        }
        file: File
        fileName: string
        step_uuid: string
        q_id: string
      }
    }
  }
}

export const ClientApplication: React.FC = () => {
  const [isStepIdChanged, setIsStepIdChanged] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { menu, clientApplication, template: templateActions } = constructorActions

  const template = useSelector(constructorTemplateSelector)
  const steps = useSelector(templateStepsSelector)
  const currentStep = useSelector(currentStepIdSelector)
  const { isLoading, currentStepIndex, templateBEId, mandatoryQuestionsList } = useSelector(
    clientApplicationDataSelector
  )
  const { appFormFlowData } = useSelector(userFlowSelector)

  const methods = useForm({ mode: 'onBlur' })
  const { formState, handleSubmit, clearErrors, setError, reset } = methods

  const isFormEdited = true

  const handleGetApplicationTemplate = useCallback(() => {
    dispatch(clientApplication.getClientApplication())
  }, [clientApplication, dispatch])

  const handleGetCurrentApplicationsStepData = useCallback(() => {
    if (templateBEId) {
      dispatch(clientApplication.getCurrentStep({ stepId: currentStep, templateBEId }))
    }
  }, [clientApplication, currentStep, dispatch, templateBEId])

  const handleChangeTab = useCallback(
    stepId => {
      dispatch(menu.setCurrentStep(stepId))
    },
    [dispatch, menu]
  )

  const handleOnPrevStepButtonClick = useCallback(() => {
    if (appFormFlowData) {
      dispatch(updateAppFormUserFlowThunk({ step: appFormFlowData.flow_data.step - 1 }))
    }
  }, [appFormFlowData, dispatch])

  const handleOnBackButtonClick = useCallback(() => {
    dispatch(clientApplication.setCurrentStepIndex(currentStepIndex - 1))
  }, [clientApplication, currentStepIndex, dispatch])

  const filledMandatoryQuestionsHandler = useCallback(
    (data: any) => {
      const list: { [key: string]: string } = {}
      const filledQuestions: { [key: string]: string } = {}

      Object.assign(list, ...Object.values(data))

      for (const key in list) {
        // if (mandatoryQuestionsList && mandatoryQuestionsList.includes(key)) {
        if (key) {
          if (typeof list[key] === 'object') {
            // @ts-ignore
            filledQuestions[key] = moment(moment(list[key]).toDate()).format('YYYY-MM-DD')
          } else {
            filledQuestions[key] = list[key]?.includes('00:00.000Z') ? list[key].split('T')[0] : list[key]
          }
        }
      }
      dispatch(
        clientApplication.postFilledMandatoryQuestions({
          questionnaire: templateBEId!,
          answered_on_questions: filledQuestions
        })
      )
    },
    [clientApplication, dispatch, templateBEId]
  )

  const handleSaveCurrentStep = useCallback(() => {
    handleSubmit((data: IFormFields) => {
      let stepFiles = null
      let errorFile = false
      let stepData = data
      if ('__files' in data) {
        if (data?.__files) {
          for (const key in data?.__files) {
            if (data?.__files[key]) {
              clearErrors(`__files.${key}`)
              errorFile = false
            } else {
              errorFile = true
              setError(`__files.${key}`, { type: 'error', message: 'Field required' })
            }
          }
        }
        const { __files, ...restData } = data
        stepFiles = __files
        stepData = restData
      }

      if (errorFile || Object.keys(formState?.errors)?.length !== 0) {
        return
      }

      if (template && templateBEId && steps) {
        if (currentStep.includes('constructor')) {
          const newId = uuidv4()

          dispatch(
            clientApplication.postCurrentStep({
              step_uuid: newId,
              questionnaire: templateBEId,
              elements: stepData,
              stepFiles,
              isFormEdited,
              reset
            })
          )

          dispatch(templateActions.editStep({ ...steps[currentStepIndex], id: newId }))
          setIsStepIdChanged(true)
        } else {
          clearErrors('__file')
          dispatch(
            clientApplication.postCurrentStep({
              step_uuid: currentStep,
              questionnaire: templateBEId,
              elements: stepData,
              stepFiles,
              isFormEdited,
              reset
            })
          )
        }

        if (isFormEdited) {
          filledMandatoryQuestionsHandler(stepData)
        }
      }
    })()
  }, [
    clientApplication,
    currentStep,
    currentStepIndex,
    dispatch,
    filledMandatoryQuestionsHandler,
    handleSubmit,
    isFormEdited,
    reset,
    steps,
    template,
    templateActions,
    templateBEId
  ])

  const handleUpdateCurrentTemplateByUser = useCallback(() => {
    if (templateBEId && template) {
      dispatch(
        clientApplication.updateCurrentUserTemplate({
          questionnaire: templateBEId,
          filled_template: template
        })
      )
    }
  }, [clientApplication, dispatch, template, templateBEId])

  useEffect(() => {
    handleGetApplicationTemplate()
  }, [handleGetApplicationTemplate])

  useEffect(() => {
    if (isStepIdChanged) {
      handleUpdateCurrentTemplateByUser()
      setIsStepIdChanged(false)
    }
  }, [handleUpdateCurrentTemplateByUser, isStepIdChanged])

  useEffect(() => {
    if (steps && steps.length > 0) {
      handleChangeTab(steps[currentStepIndex].id)
    }
  }, [handleChangeTab, currentStepIndex, steps, currentStep])

  useEffect(() => {
    if (currentStep && !currentStep.includes('constructor')) {
      handleGetCurrentApplicationsStepData()
    }
  }, [handleGetCurrentApplicationsStepData, currentStep])

  if (steps?.length === 0) return <Box>Steps were not added in the created template</Box>

  return (
    <ThemeProvider theme={applicationFormTheme}>
      <FormProvider {...methods}>
        <Container>
          <Box>
            {steps && steps.length ? (
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'hidden',
                  p: 2,
                  borderRadius: 4,
                  boxShadow: '4px 4px 19px rgba(0, 0, 0, 0.25)'
                }}
              >
                {isLoading && <LoadingOverlay isVisible={isLoading} />}
                <Tabs sx={{ mb: 4 }} value={currentStepIndex}>
                  {steps.map(({ title, id }) => (
                    <Tab
                      key={id}
                      label={<Typography variant="h4">{title}</Typography>}
                      id={id}
                      aria-controls={id}
                      disabled
                    />
                  ))}
                </Tabs>
                <ConstructorStep />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <Button
                    sx={{
                      marginRight: '10px',
                      backgroundColor: '#F46B6B',
                      ':hover, :focus': { backgroundColor: '#F46B6B' }
                    }}
                    onClick={
                      currentStepIndex > 0 ? handleOnBackButtonClick : handleOnPrevStepButtonClick
                    }
                  >
                    {currentStepIndex > 0 ? t('common.buttons.back') : t('common.buttons.prev')}
                  </Button>
                  <Button onClick={handleSaveCurrentStep}>
                    {currentStepIndex + 1 === steps.length ? t('common.buttons.finish') : t('common.buttons.next')}
                  </Button>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Container>
      </FormProvider>
    </ThemeProvider>
  )
}
