import i18next from 'i18next'

import { COUNTRY } from 'config'

import translationActions from './translationActions'
import { actionTypes, ISetLanguage, translationsState } from './translationTypes'

const initialState: translationsState = {
  currentLanguage: COUNTRY === 'Ukraine' ? 'uk' : 'en'
}

export const translationReducer = (state = initialState, action: actionTypes): translationsState => {
  switch (action.type) {
    case translationActions.SET_LANGUAGE:
      i18next.changeLanguage(action.payload.language)
      return { ...state, currentLanguage: action.payload.language }
    default:
      return state
  }
}

export const setLanguage = (language: string): ISetLanguage => ({
  type: translationActions.SET_LANGUAGE,
  payload: { language }
})
