import { createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'

import { axiosInstancePrivate } from 'api/api'
import { setLenderToastMessage } from 'redux/reducers/lender/allState/lenderReducer'

export const fetchRegions = createAsyncThunk<string[], void, { rejectValue: string }>(
  'lender/productForm/fetchRegions',
  async (_, thunkAPI) => {
    try {
      const res = await axiosInstancePrivate.get<string[]>('lender/regions/')

      if (!res.data) {
        const errText = i18next.t('lender.creditPolicyForm.errors.noData')
        thunkAPI.dispatch(setLenderToastMessage({
          message: errText,
          type: 'error'
        }))
        return thunkAPI.rejectWithValue(errText)
      }

      return res.data
    } catch (e: any) {
      const msg: string = e.message

      thunkAPI.dispatch(setLenderToastMessage({
        message: msg,
        type: 'error'
      }))

      return thunkAPI.rejectWithValue(msg)
    }
  })
