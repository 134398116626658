import { Box, Button, styled, TextField, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import MyModal from 'components/UI/MyModal'
import PaperContainer from 'components/UI/PaperContainer'
import { textInputModalSelector } from 'redux/reducers/common/textInputModal/selectors'
import { textInputModalActions } from 'redux/reducers/common/textInputModal/slice'

const Container = styled(PaperContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`

const TextInputModal = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const state = useSelector(textInputModalSelector)

  const handleConfirm = () => {
    state.confirmAction && state.confirmAction(state.value)
    dispatch(textInputModalActions.close())
  }

  const handleClose = () => {
    dispatch(textInputModalActions.close())
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(textInputModalActions.setValue(e.target.value))
  }

  return (
    <MyModal isVisible={state.isActive} closeModal={handleClose}>
      <Container>
        <Typography>
          {state.text}
        </Typography>
        <TextField
          value={state.value}
          onChange={handleInputChange}
          fullWidth
        />
        <Box>
          <Button
            onClick={handleClose}
          >
            {t('common.confirmation.cancel')}
          </Button>

          <Button
            onClick={handleConfirm}
          >
            {t('common.confirmation.confirm')}
          </Button>
        </Box>
      </Container>
    </MyModal>
  )
}

export default TextInputModal
