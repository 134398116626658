import { axiosInstance } from 'api/api'
import { getCookie } from 'utils/common/apiHelpers'

type BankIdAuthRequestsResponseType = {
  state: string | null
  dataset: any | null
  authorization_code: string
  access_token: string
  application: any
  customer_crypto: string | null
  decrypted_data: string | null
}

export async function fetchBankIdRequests (appId: number) {
  try {
    const { status, data } = await axiosInstance.get<BankIdAuthRequestsResponseType>(`/client/applications/${appId}/bankid-auth-requests/`, {
      headers: {
        Authorization: `Token ${getCookie('token')}`
      }
    })

    if (!data) {
      return {
        error: 'No data',
        status
      }
    }

    return {
      status,
      data
    }
  } catch (e: any) {
    return { error: e.message }
  }
}
