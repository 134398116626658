import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectProps,
  Tooltip,
  SxProps
} from '@mui/material'
import Zoom from '@mui/material/Zoom'
import React, { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

import iconWarning from 'assets/images/icons/warning.png'
import { ICustomSelectInput } from 'features/formConstructor/types'

interface IProps {
  cInput: ICustomSelectInput
  styles?: SxProps
  cError?: FieldError | undefined
}

export type TCustomSelectInputProps = IProps & SelectProps

export const CustomSelectInput = forwardRef<HTMLDivElement, TCustomSelectInputProps>(
  ({ cInput, styles, cError, ...props }, ref) => {
    const { options, question } = cInput

    return (
      <FormControl fullWidth sx={styles} error={!!cError}>
        <FormLabel>{question}</FormLabel>
        <Select
          {...props}
          ref={ref}
          sx={{ position: 'relative' }}
          endAdornment={
            cError && <Tooltip
              title={cError.message ?? ''}
              arrow
              TransitionComponent={Zoom}
              enterTouchDelay={0}
            >
                <span style={{ position: 'absolute', right: '44px', cursor: 'help' }}>
                  <img
                    src={iconWarning}
                    alt="Warning icon"
                    style={{ width: '20px', pointerEvents: 'none' }}
                  />
                </span>
            </Tooltip>
          }
        >
          {options &&
            options.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
  }
)

CustomSelectInput.displayName = 'CustomSelectInput'
