import CloseIcon from '@mui/icons-material/Close'
import {
  Breakpoint,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled
} from '@mui/material'
import React from 'react'

interface IDialogProps {
  isOpen: boolean;
  title: string;
  maxWidth?: Breakpoint
  onCloseClick: () => void;
}

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    position: 'relative',
    padding: '1.2rem 0.8rem',
    borderRadius: '30px'
  }
}))

export const MyDialog: React.FC<IDialogProps> = ({
  isOpen, title, maxWidth, onCloseClick, children
}) => {
  return <BootstrapDialog
    fullWidth
    maxWidth={maxWidth ?? false}
    open={isOpen}
    onClose={onCloseClick}
    sx={{
      width: 'fit-content',
      margin: 'auto'
    }}
  >
      <DialogTitle sx={{ fontSize: '1.5rem', textAlign: 'center' }}>
        { title }
      </DialogTitle>
      { children &&
        <DialogContent>
          { children }
        </DialogContent>
      }
      <IconButton
        aria-label="close"
        size="large"
        sx={{ position: 'absolute', top: '15px', right: '15px', width: '40px', height: '40px' }}
        onClick={onCloseClick}
      >
        <CloseIcon />
      </IconButton>
    </BootstrapDialog>
}
