import { AxiosResponse } from 'axios'

import { axiosInstancePrivate } from 'api/api'
import { IContract } from 'redux/reducers/client/allState/types/stateTypes'
import { IChangePasswordData, IRecoverPasswordData } from 'redux/reducers/services/servicesTypes'

export const servicesApi = {
  recoverPasswordGETRequest: (email: string) => {
    return axiosInstancePrivate
      .post('helpers/forgot_password/', { email })
      .catch(err => err.response)
  },
  recoverPasswordPOSTRequest: (data: IRecoverPasswordData): Promise<AxiosResponse<any>> => {
    return axiosInstancePrivate
      .post('helpers/password_change/', data)
      .catch(err => err.response)
  },
  changePasswordRequest: (data: IChangePasswordData): Promise<AxiosResponse<any>> => {
    return axiosInstancePrivate
      .post('helpers/change_password/', data)
      .catch(err => err.response)
  },
  getClientContracts: () => {
    return axiosInstancePrivate.get('client/contracts/').catch(err => err.response)
  },
  getAllContracts: (): Promise<AxiosResponse<IContract[]>> =>
    axiosInstancePrivate.get('client/contracts/'),
  getClientRequestPayentInfo: (applicationId: number) => {
    return axiosInstancePrivate
      .get(`platon/request_payment_info/${applicationId}`)
      .catch(err => err.response)
  }
}
