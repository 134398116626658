import { ErrorMessage } from '@hookform/error-message'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel, MenuItem,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'

import { handleFloatInput } from 'utils/common/forms'
import { getValidationByFieldType } from 'utils/valiadtion/validations'

import cls from '../3_questionaryPage/inputs/inputs.module.scss'

interface IGuarantorFormProps {
  submitHandler: (data: any) => void
  isFetching: boolean
}

const GuarantorForm: React.FC<IGuarantorFormProps> = ({ submitHandler, isFetching }) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  })

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Typography variant="h2" align="center" mb={3} fontWeight={500}>
        {t('client.dashboard.modal.guarantor.title')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
        <FormControl>
          <TextField
            {...register('email', {
              required: {
                value: true,
                message: t('formValidation.required')
              },
              pattern: getValidationByFieldType('email', t)
            })}
            variant="standard"
            type="email"
            label={t('client.dashboard.modal.guarantor.label.email')}
          />
          <FormHelperText error>
            <ErrorMessage name="email" errors={errors} />
          </FormHelperText>
        </FormControl>
        <FormControl>
          <Controller
            control={control}
            name="phone_number"
            rules={{
              required: {
                value: true,
                message: t('formValidation.required')
              },
              pattern: getValidationByFieldType('tel', t)
            }}
            render={({ field: { ref, ...field } }) => (
              <PhoneInput
                inputProps={{ ref }}
                inputClass={cls.inputPhone}
                buttonClass={cls.btnFlag}
                country={'ua'}
                specialLabel={''}
                {...field}
              />
            )}
          />
          {/* <TextField
            {...register('phone_number', {
              required: {
                value: true,
                message: 'Field is required'
              },
              pattern: getValidationByFieldType('tel', t)
            })}
            variant="standard"
            type="tel"
            label={'Phone'}
          /> */}
          <FormHelperText error>
            <ErrorMessage name="phone_number" errors={errors} />
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            {...register('last_name', {
              required: {
                value: true,
                message: t('formValidation.required')
              }
            })}
            variant="standard"
            type="text"
            label={t('client.dashboard.modal.guarantor.label.lastName')}
          />
          <FormHelperText error>
            <ErrorMessage name="last_name" errors={errors} />
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            {...register('first_name', {
              required: {
                value: true,
                message: t('formValidation.required')
              }
            })}
            variant="standard"
            type="text"
            label={t('client.dashboard.modal.guarantor.label.firstName')}
          />
          <FormHelperText error>
            <ErrorMessage name="first_name" errors={errors} />
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            {...register('middle_name', {
              required: {
                value: true,
                message: t('formValidation.required')
              }
            })}
            variant="standard"
            type="text"
            label={t('client.dashboard.modal.guarantor.label.middleName')}
          />
          <FormHelperText error>
            <ErrorMessage name="middle_name" errors={errors} />
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            {...register('address', {
              required: {
                value: true,
                message: t('formValidation.required')
              }
            })}
            variant="standard"
            type="text"
            label={t('client.dashboard.modal.guarantor.label.address')}
          />
          <FormHelperText error>
            <ErrorMessage name="address" errors={errors} />
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>{t('client.dashboard.modal.guarantor.label.dateOfIssue')}</FormLabel>
          <TextField
            {...register('date_of_issue', {
              required: {
                value: true,
                message: t('formValidation.required')
              }
            })}
            type="date"
          />
          <FormHelperText error>
            <ErrorMessage name="date_of_issue" errors={errors} />
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>{t('client.dashboard.modal.guarantor.label.identificationNumber')}</FormLabel>
          <TextField
            {...register('identification_number', {
              required: {
                value: true,
                message: t('formValidation.required')
              }
            })}
            onChange={handleFloatInput}
          />
          <FormHelperText error>
            <ErrorMessage name="identification_number" errors={errors} />
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            {...register('issued_by', {
              required: {
                value: true,
                message: t('formValidation.required')
              }
            })}
            label={t('client.dashboard.modal.guarantor.label.issuedBy')}
          />
          <FormHelperText error>
            <ErrorMessage name="issued_by" errors={errors} />
          </FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            {...register('passport', {
              required: {
                value: true,
                message: t('formValidation.required')
              }
            })}
            label={t('client.dashboard.modal.guarantor.label.passport')}
          />
          <FormHelperText error>
            <ErrorMessage name="passport" errors={errors} />
          </FormHelperText>
        </FormControl>
        <Controller
          control={control}
          name="passport_type"
          rules={{ required: t('formValidation.required') }}
          render={({ field: { value, onChange } }) => (
            <FormControl>
              <TextField label={t('client.dashboard.modal.guarantor.label.passportType.title')} select value={value || ''} onChange={onChange}>
                <MenuItem value="ID card">
                  {t('client.dashboard.modal.guarantor.label.passportType.item.idCard')}
                </MenuItem>
                <MenuItem value="Series">
                  {t('client.dashboard.modal.guarantor.label.passportType.item.series')}
                </MenuItem>
              </TextField>
              <FormHelperText error>
                <ErrorMessage name="passport_type" errors={errors} />
              </FormHelperText>
            </FormControl>
          )}
        />
      </Box>
      <Button sx={{ position: 'relative' }} variant="contained" type="submit" disabled={isFetching}>
        {t('common.buttons.save')}
        {isFetching && (
          <CircularProgress
            size={24}
            sx={{
              color: 'green',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px'
            }}
          />
        )}
      </Button>
    </form>
  )
}

export default GuarantorForm
