import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { AppBar, Button, Toolbar } from '@mui/material'
import React, { ReactChild } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { authApi } from 'api/authApi'
import { currentUserSelector } from 'redux/reducers/auth/authSelectors'
import { logoutThunk } from 'redux/reducers/auth/authThunks'
import { TState } from 'redux/store'

import LanguageMenu from '../languageMenu/languageMenu'

import cls from './headerNav.module.scss'

type Page = 'home'

interface IProps {
  page?: Page
  isFetching: boolean
  children?: ReactChild | null | undefined
  logoutThunk: () => any
}

const HeaderNav: React.FC<IProps> = ({ logoutThunk, isFetching, page, children }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const currentUser = useSelector(currentUserSelector)

  const logoutHandler = () => {
    logoutThunk()
    history.push('/')
  }

  const loginHandler = async () => {
    switch (currentUser?.group) {
      case 'Client': {
        history.push('/client')
        break
      }
      case 'Admin':
      case 'Lead':
      case 'Lead:Middle':
      case 'Lead:Soft':
      case 'Lead:Verifier':
      case 'Lender':
      case 'Manager':
      case 'Verifier': {
        history.push('/lender')
        break
      }
      case 'Commerce': {
        history.push('/commerce')
        break
      }
    }

    if (!currentUser) {
      history.push('/login')
    }

    const res = await authApi.application.getFlowInfo()
  }
  return (
    <AppBar position="static" className={cls.header}>
      <Toolbar>
        {children}
        <LanguageMenu sx={{ ml: 'auto' }} />
        {page === 'home' ? (
          <Button
            onClick={loginHandler}
            disabled={isFetching}
            variant="outlined"
            className={`${cls.loginBtn} ${cls.authBtn}`}
          >
            {t('common.auth.login')}
            <ArrowRightAltIcon className={cls.loginIcon} />
          </Button>
        ) : (
          <Button
            onClick={logoutHandler}
            disabled={isFetching}
            variant="outlined"
            className={`${cls.logoutBtn} ${cls.authBtn}`}
          >
            <ArrowRightAltIcon className={cls.logoutIcon} />
            {t('common.auth.logout')}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )
}
const mapStateToProps = (state: TState) => ({
  isFetching: state.auth.isFetching
})
export default connect(mapStateToProps, { logoutThunk })(HeaderNav)
