import { combineReducers } from 'redux'

import { confirmModalReducer } from 'redux/reducers/common/confirmModal/confirmModalSlice'
import { navbarReducer } from 'redux/reducers/common/navbar/navbarSlice'
import { textInputModalReducer } from 'redux/reducers/common/textInputModal/slice'

export const commonReducer = combineReducers({
  confirmModal: confirmModalReducer,
  textInputModal: textInputModalReducer,
  navbar: navbarReducer
})
