import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import Zoom from '@mui/material/Zoom'
import { saveAs } from 'file-saver'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import iconWarning from 'assets/images/icons/warning.png'
import { API_URL } from 'config'
import { ICustomInput } from 'features/formConstructor/types'
import { setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'
import { constructorActions } from 'redux/reducers/constructors/formConstructor/state/actions'
import {
  clientApplicationDataSelector,
  currentStepIdSelector
} from 'redux/reducers/constructors/formConstructor/state/selectors'
import { getMbToBytes } from 'utils/helpers'

import ConstructorDeleteFileIcon from '../icons/ConstructorDeleteFileIcon'
import ConstructorUploadFileIcon from '../icons/ConstructorUploadFileIcon'

interface IProps {
  input: ICustomInput
  blockName: string
}

const CustomFileInput: React.FC<IProps> = ({ input, blockName }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id, question } = input

  const [srcImage, setSrcImage]: [string | null, any] = useState(null)

  const { register, watch, setValue, clearErrors, formState: { errors } } = useFormContext()

  const step_uuid = useSelector(currentStepIdSelector)
  const { templateBEId } = useSelector(clientApplicationDataSelector)

  useEffect(() => {
    register(`__files.${id}`, {
      required: true
    })
  }, [id, register])

  const handleUpload = async () => {
    if (fileData) {
      const { file, file_name } = fileData

      if (typeof file === 'string' && API_URL) {
        const fileRes = await dispatch(
          constructorActions.clientApplication.getStepFile(fileData.id)
        )
        // @ts-ignore
        const fileBlob = fileRes.payload
        if (fileBlob) {
          saveAs(fileBlob, file_name)
        }
      }

      if (file instanceof File) {
        saveAs(fileData.file, file_name)
      }
    }
  }

  const handleClear = async () => {
    if (fileData) {
      const { file } = fileData

      if (typeof file === 'string' && templateBEId) {
        const deleteRes = await dispatch(
          constructorActions.clientApplication.deleteStepFile({
            step_uuid,
            q_id: templateBEId,
            doc_id: fileData.id
          })
        )
        // @ts-ignore
        const isDeleted = deleteRes.payload
        if (isDeleted) {
          setValue(`__files.${id}`, '')
        }
      }

      if (file instanceof File) {
        setValue(`__files.${id}`, '')
      }
    }
  }

  const handleInputChange = useCallback(
    e => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0]
        if (file.size < getMbToBytes(50)) {
          if (file?.type?.includes('image/')) {
            setSrcImage(URL.createObjectURL(file))
          }
          setValue(`__files.${id}`, {
            file,
            file_name: file.name,
            elements: JSON.stringify({ inputId: `${id}` })
          })
          clearErrors(`__files.${id}`)
        } else {
          dispatch(setClientToastMessage({ type: 'error', message: t('formValidation.maxSizeFile', { n: 50 }) }))
        }
      }
    },
    [id, setValue, clearErrors, dispatch]
  )

  const fileData = watch(`__files.${id}`)
  return (
    <>
      {/* @ts-ignore */}
      <div style={{ padding: '25px', height: '100%', border: `1px solid ${errors?.__files?.[id] ? 'red' : '#c4c4c4'}`, borderRadius: '20px', display: 'flex', alignItems: 'center' }}>
        <div>
          <label htmlFor={id}>
            <input
              disabled={!!fileData?.file_name?.length}
              id={id}
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleInputChange}
            />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: fileData?.file_name ? '18px' : 0
              }}
            >
              <Button
                disabled={!!fileData?.file_name?.length}
                sx={{
                  flex: '0 0 auto',
                  p: '8px 14px',
                  mr: 2,
                  borderRadius: '30px',
                  fontSize: '13px',
                  lineHeight: '15.26px'
                }}
                component="span"
              >
                Select File
              </Button>
              <Typography>{question}</Typography>
            </Box>
          </label>
          {fileData?.file_name && (
            <>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <IconButton onClick={handleClear} sx={{ p: 0, mr: '12px' }}>
                  <ConstructorDeleteFileIcon />
                </IconButton>
                <IconButton onClick={handleUpload} sx={{ p: 0, mr: '15px' }}>
                  <ConstructorUploadFileIcon />
                </IconButton>
                {srcImage === null && <Typography variant="body1">{fileData?.file_name}</Typography>}
              </Box>
              {srcImage && (
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <img src={srcImage} alt="images form" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                </Box>
              )}
            </>
          )}
        </div>
        {/* {errors?.__files && <Tooltip */}
        {/*  title="Field required" */}
        {/*  arrow */}
        {/*  TransitionComponent={Zoom} */}
        {/*  enterTouchDelay={0} */}
        {/* > */}
        {/*  <span style={{ position: 'absolute', right: '44px', cursor: 'help' }}> */}
        {/*    <img */}
        {/*      src={iconWarning} */}
        {/*      alt="Warning icon" */}
        {/*      style={{ width: '20px', pointerEvents: 'none' }} */}
        {/*    /> */}
        {/*  </span> */}
        {/* </Tooltip>} */}
      </div>
    </>
  )
}

export default CustomFileInput
