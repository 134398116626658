import { styled, TableCell, tableCellClasses, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const TranchesTableHead = () => {
  const { t } = useTranslation()

  const rows = [
    t('client.applicationForm.tranches.table.head.date'),
    t('client.applicationForm.tranches.table.head.amount'),
    t('client.applicationForm.tranches.table.head.actions')
  ]

  return (
    <TableHead>
      <TableRow>
        {rows.map(row => (
          <StyledTableCell key={row}>
            {row}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default TranchesTableHead
