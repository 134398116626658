import { styled, TableBody, TableCell, tableCellClasses, TableRow } from '@mui/material'
import moment from 'moment/moment'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { paymentGraphicSelector } from 'redux/reducers/client/allState/clientSelectors'
import { DATE_FORMAT, getDate } from 'utils/getDate'
import { roundNumber } from 'utils/roundValues'

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-last-of-type(2)': {
    borderBottom: '3px solid black'
  }
}))

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

const PaymentGraphicTableBody: FC = () => {
  const paymentGraphic = useSelector(paymentGraphicSelector)
  const { t } = useTranslation()

  return (
    <TableBody>
      {paymentGraphic?.map(item => (
        <StyledTableRow key={item.id}>
          <StyledTableCell>{moment(item.date).format(DATE_FORMAT)}</StyledTableCell>
          <StyledTableCell>{roundNumber(item.principal)}</StyledTableCell>
          <StyledTableCell>{roundNumber(item.interest)}</StyledTableCell>
          <StyledTableCell>{roundNumber(item.commission)}</StyledTableCell>
          <StyledTableCell>{roundNumber(+item.principal + +item.interest + +item.commission)}</StyledTableCell>
        </StyledTableRow>
      ))}
      <StyledTableRow>
        <StyledTableCell>
          <span style={{ fontWeight: 'bold' }}>{t('client.applicationForm.paymentGraphicTable.header.total')}</span>
        </StyledTableCell>
        <StyledTableCell>
          {roundNumber(
            paymentGraphic?.reduce((accumulator, object) => {
              return accumulator + Number(object.principal)
            }, 0)
          )}
        </StyledTableCell>
        <StyledTableCell>
          {roundNumber(
            paymentGraphic?.reduce((accumulator, object) => {
              return accumulator + Number(object.interest)
            }, 0)
          )}
        </StyledTableCell>
        <StyledTableCell>
          {roundNumber(
            paymentGraphic?.reduce((accumulator, object) => {
              return accumulator + Number(object.commission)
            }, 0)
          )}
        </StyledTableCell>
        <StyledTableCell>
          {roundNumber(
            paymentGraphic?.reduce((accumulator, object) => {
              return accumulator + Number(object.principal) + Number(object.commission) + Number(object.interest)
            }, 0)
          )}
        </StyledTableCell>
      </StyledTableRow>
    </TableBody>
  )
}

export default PaymentGraphicTableBody
