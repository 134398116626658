import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import React, { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { mobileMaxWidth } from 'features/formConstructor/themes'
import { ICustomInput } from 'features/formConstructor/types'
import { getPattern } from 'features/formConstructor/utils/validation'
import { constructorActions } from 'redux/reducers/constructors/formConstructor/state/actions'
import { clientApplicationDataSelector } from 'redux/reducers/constructors/formConstructor/state/selectors'
import { ITemplateBlock } from 'redux/reducers/constructors/formConstructor/types'
import { languageSelector } from 'redux/reducers/translation/translationSelectors'
import { replaceCyrillic } from 'utils/helpers'

import { CustomDateInput } from '../common/inputs/CustomDateInput'
import CustomFileInput from '../common/inputs/CustomFileInput'
import { CustomPhoneInput } from '../common/inputs/CustomPhoneInput'
import { CustomSelectInput } from '../common/inputs/CustomSelectInput'
import { CustomTextInput } from '../common/inputs/CustomTextInput'

export interface IConstructorBlockProps {
  block: ITemplateBlock,
  autofillData?: any
}

const getQuestionName = (input: ICustomInput, lang: string) => {
  let questionResult = ''
  switch (lang) {
    case 'ru': {
      questionResult = input.question_ru || input.question
      break
    }
    case 'uk': {
      questionResult = input.question_uk || input.question
      break
    }
    case 'en': {
      questionResult = input.question_en || input.question
      break
    }
    default: {
      questionResult = input.question
      break
    }
  }

  return questionResult
}
// NEED TO ADD DINAMIC TYPE FOR FORM FIELDS

export const ConstructorBlock: React.FC<IConstructorBlockProps> = ({ block, autofillData }) => {
  const dispatch = useDispatch()

  const { title } = block
  const blockName = replaceCyrillic(title.toLowerCase()
    .replace(/[\s,]/g, '_')
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>/]/gi, '_'))

  const { clientApplication } = constructorActions
  const { mandatoryQuestionsList } = useSelector(clientApplicationDataSelector)
  const currentLanguage = useSelector(languageSelector)

  const { control, setValue } = useFormContext()

  const isMobile = useMediaQuery(mobileMaxWidth)

  const sortedInputs = useMemo(
    () => [...block.inputs].sort((a, b) => a.styles.order - b.styles.order),
    [block.inputs]
  )

  useEffect(() => {
    for (const key of block.inputs) {
      // if (key.isMandatoryQuestion && !mandatoryQuestionsList?.includes(key.name)) {
      debugger // eslint-disable-line no-debugger
      if (key?.name) {
        dispatch(clientApplication.setClientsMandatoryQuestionsList(key.name))
      }
    }
  }, [block.inputs, clientApplication, dispatch])

  useEffect(() => {
    if (autofillData) {
      for (const keyAutoFill of Object.keys(autofillData)) {
        for (const keyInput of block?.inputs) {
          if (keyInput?.name === keyAutoFill) {
            setValue(`${blockName}.${keyAutoFill}`, autofillData[keyAutoFill])
          }
        }
      }
    }
  }, [autofillData, block.inputs])

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" mb={2}>
        {block.title}
      </Typography>
      {sortedInputs.length ? (
        <Grid
          container
          spacing={block.inputs.length ? 3 : 0}
          mb={2}
          sx={{ alignItems: 'flex-end' }}
        >
          {sortedInputs.map(input => {
            const { id, name, type, styles, options, question } = input
            // TODO: Type this data after first priority tasks
            const replaceName = replaceCyrillic(name ? name?.replace(/[\s,\\()']/g, '_') : '')
            const replaceQuestion = replaceCyrillic(getQuestionName(input, currentLanguage) ? getQuestionName(input, currentLanguage)?.toLowerCase()?.replace(/[\s,\\()'/]/g, '_') : '')

            return (
              <Grid
                item
                key={id}
                xs={isMobile ? 12 : styles.size}
                sx={{ alignSelf: type === 'file' ? 'stretch' : 'flex-end' }}
              >
                {['text', 'number', 'password', 'email'].includes(type) && (
                  <Controller
                    name={`${blockName}.${replaceName || replaceQuestion}`}
                    control={control}
                    defaultValue={''}
                    rules={{
                      required: 'The field must be filled',
                      pattern: getPattern(type)
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomTextInput {...field} input={input} cError={error} />
                    )}
                  />
                )}

                {type === 'phone' && (
                  <Controller
                    name={`${blockName}.${replaceName}`}
                    control={control}
                    rules={{ required: 'The field must be filled' }}
                    render={({ field, fieldState: { error } }) => (
                      <CustomPhoneInput {...field} input={input} cError={error} />
                    )}
                  />
                )}

                {type === 'date' && (
                  <Controller
                    name={`${blockName}.${replaceName}`}
                    control={control}
                    rules={{ required: 'The field must be filled' }}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        {...field}
                        disableFuture
                        renderInput={params => (
                          <CustomDateInput input={input} {...params} cError={error} />
                        )}
                      />
                    )}
                  />
                )}

                {type === 'select' && options && (
                  <Controller
                    name={`${blockName}.${replaceName}`}
                    control={control}
                    rules={{ required: { value: true, message: 'The field must be filled' } }}
                    defaultValue=""
                    render={({ field, fieldState: { error } }) => (
                      <CustomSelectInput cInput={input} {...field} cError={error} />
                    )}
                  />
                )}

                {type === 'file' && <CustomFileInput input={input} blockName={blockName} />}
              </Grid>
            )
          })}
        </Grid>
      ) : null}
    </Box>
  )
}
