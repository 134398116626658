import { AxiosPromise, AxiosResponse } from 'axios'

import { axiosInstancePrivate } from 'api/api'
import {
  IBETemplate,
  ICurrentTemplatesStep,
  ITemplate,
  ITemplateRequest,
  IClientTemplateUpdateData
} from 'redux/reducers/constructors/formConstructor/types'
import { ICreditProduct } from 'redux/reducers/lender/allState/types/state'

export const formConstructorApi = {
  getAvailableCreditProducts: (): Promise<AxiosResponse<ICreditProduct[]>> => {
    return axiosInstancePrivate.get('questionnaries/lender/template/credit-policy-list/')
  },

  getApplicationTemplates: (): Promise<AxiosResponse<IBETemplate[]>> => {
    return axiosInstancePrivate
      .get('/questionnaries/lender/templates-get/')
      .catch(err => err.response)
  },

  getCurrentApplicationTemplate: (templateId: string): Promise<AxiosResponse<IBETemplate>> => {
    return axiosInstancePrivate
      .get(`/questionnaries/lender/template-retrieve/${templateId}/`)
      .catch(err => err.response)
  },
  postApplicationTemplate: ({
    userId,
    templateId,
    template
  }: ITemplateRequest): Promise<AxiosResponse<ITemplate>> => {
    return axiosInstancePrivate
      .post('questionnaries/lender/template-create/', {
        creator: userId,
        q_uuid: templateId,
        q_type: template.type,
        credit_policy: template.credit_policy,
        title: template.title,
        elements: template
      })
      .catch(err => err.response)
  },
  updateCurrentApplicationTemplate: ({
    userId,
    templateId,
    template,
    addedMandatoryQuestions
  }: ITemplateRequest): Promise<AxiosResponse<ITemplate>> => {
    return axiosInstancePrivate
      .put(`/questionnaries/lender/template-retrieve/${templateId}/`, {
        creator: userId,
        q_uuid: templateId,
        q_type: template.type,
        credit_policy: template.credit_policy,
        title: template.title,
        elements: template,
        added_questions: addedMandatoryQuestions
      })
      .catch(err => err.response)
  },
  deleteCurrentApplicationTemplate: (templateId: string) => {
    return axiosInstancePrivate
      .delete(`/questionnaries/lender/template-retrieve/${templateId}/`)
      .catch(err => err.response)
  },

  // CLIENT
  getCurrentApplicationsStep: (payload: {
    stepId: string
    templateBEId: number
  }): Promise<AxiosResponse<any>> => {
    return axiosInstancePrivate.get(
      `/questionnaries/client/step-retrieve/${payload.stepId}/${payload.templateBEId}/`
    )
  },
  // TODO: Need to give a type to the final version data coming from the backend
  postCurrentApplicationsStep: (data: Partial<ICurrentTemplatesStep>) => {
    return axiosInstancePrivate.post('/questionnaries/client/step-create/', data)
  },
  postCurrentAppStepFile: (appId: number, fileData: FormData): AxiosPromise<any> =>
    axiosInstancePrivate.post(`client/applications/${appId}/questionnaire-documents/`, fileData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),
  getClientApplication: (): Promise<AxiosResponse<any>> => {
    return axiosInstancePrivate.get('/questionnaries/client/questionnaire-get/')
  },
  // TODO: Need to give a type to the final version data coming from the backend

  updateCurrentUserTemplate: (data: IClientTemplateUpdateData) => {
    return axiosInstancePrivate.put(
      `/questionnaries/client/questionnaire-update/${data.questionnaire}/`,
      data
    )
  },

  postFilledMandatoryQuestions: (data: {
    questionnaire: number
    answered_on_questions: Object
  }) => {
    return axiosInstancePrivate.patch(
      `/questionnaries/client/questionnaire/answer/${data.questionnaire}/`,
      data.answered_on_questions
    )
  },

  deleteStepFile: (data: {
    step_uuid: string
    q_id: number
    doc_id: string
  }): AxiosPromise<any> => {
    return axiosInstancePrivate.delete('questionnaries/client/delete-document/', {
      data
    })
  },
  getStepFile: (doc_id: number): AxiosPromise<Blob> => {
    return axiosInstancePrivate.get(`questionnaries/client/download-document/${doc_id}/`, {
      responseType: 'blob'
    })
  }
}
