import { ThunkAction } from 'redux-thunk'

import { servicesApi } from 'api/servicesApi'
import { setAppToastMessage } from 'redux/reducers/app/appReducer'
import { TState } from 'redux/store'

import history from './../../../utils/history'
import { actionTypes, IChangePasswordData, IRecoverPasswordData } from './servicesTypes'

type TServicesThunk = ThunkAction<void, TState, unknown, actionTypes>

export const recoverPasswordGETRequestThunk = (email: string): TServicesThunk =>
  async () => await servicesApi.recoverPasswordGETRequest(email)

export const recoverPasswordPOSTRequestThunk = (data: IRecoverPasswordData, reset: () => void) => async (dispatch: any) => {
  try {
    const res = await servicesApi.recoverPasswordPOSTRequest(data)

    if (res.status === 200 || res.status === 204) {
      dispatch(setAppToastMessage({ type: 'success', message: 'Successfully' }))

      setTimeout(() => {
        history.push('/login')
        window.location.reload()
        reset()
      }, 2000)
    }
    if (res.status === 400) {
      dispatch(setAppToastMessage({ type: 'error', message: `${Object.keys(res.data)}: ${Object.values(res.data)}` }))
    }
  } catch (error: any) {
    dispatch(setAppToastMessage({ type: 'error', message: error.message }))
  }
}
export const changePasswordRequestThunk = (data: IChangePasswordData, reset: () => void) => async (dispatch: any) => {
  try {
    const res = await servicesApi.changePasswordRequest(data)

    if (res.status === 200) {
      dispatch(setAppToastMessage({ type: 'success', message: 'Successfully' }))
      reset()
    }
    if (res.status === 400) {
      dispatch(setAppToastMessage({ type: 'error', message: res.data.password[0] }))
    }
  } catch (error: any) {
    dispatch(setAppToastMessage({ type: 'error', message: error.message }))
  }
}
