import { createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'

import { axiosV2InstancePrivate } from 'api/api'
import { setLenderToastMessage } from 'redux/reducers/lender/allState/lenderReducer'
import { IScoreCardQuery } from 'redux/reducers/lender/scoring/types'

export const patchQuery = createAsyncThunk(
  'patchQuery',
  async (query: IScoreCardQuery, thunkAPI) => {
    try {
      const res = await axiosV2InstancePrivate.patch<IScoreCardQuery>(`lender/scorecard/queries/${query.id}/`, query)

      if (!res.data) {
        thunkAPI.dispatch(setLenderToastMessage(i18next.t('lender.decisionEngine.rulePage.toast.postQueryNoData')))
        throw new Error()
      }

      return res.data
    } catch (e: any) {
      let message = e.response?.data?.non_field_errors
      if (!message) message = e.message

      thunkAPI.dispatch(setLenderToastMessage({ type: 'error', message }))
      thunkAPI.rejectWithValue(message)
    }
  })
