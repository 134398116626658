export const getErrorMessage = (data: any) => {
  if (typeof data === 'string') return data

  if (typeof data !== 'object') return 'Invalid data'

  const errors = Object.entries<string | string[]>(data)
  return errors.reduce((acc, [key, value], _, arr) => {
    return `${key}: ${Array.isArray(value) ? value.join(' ,') : value}${
      arr.length > 1 ? `, ${acc}` : ''
    }`
  }, '')
}
