import { Box, Modal } from '@mui/material'
import React, { ReactNode } from 'react'

interface IProps {
  isVisible: boolean;
  closeModal: () => void;
  children: ReactNode;
}

const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '100%',
  width: '55%',
  overflow: 'auto'
}

const MyModal = ({ isVisible, closeModal, children }: IProps) => {
  return (
    <Modal
      open={isVisible}
      onClose={closeModal}
    >
      <Box sx={styleModal}>
        {children}
      </Box>
    </Modal>
  )
}

export default MyModal
