import { combineReducers } from 'redux'

import clientReducer from 'redux/reducers/client/allState/clientReducer'
import { productShippingReducer } from 'redux/reducers/client/productShipping/slice'

const combinedClientReducer = combineReducers({
  all: clientReducer,
  productShipping: productShippingReducer
})

export { combinedClientReducer as clientReducer }
