import { ErrorMessage } from '@hookform/error-message'
import { Typography } from '@mui/material'
import React from 'react'

import cls from './formErrorMessage.module.scss'

interface IProps {
  errors: any
  name: string
  styles?: React.CSSProperties
}

const FormErrorMessage: React.FC<IProps> = ({ errors, name, styles }) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <Typography className={cls.error} style={styles}>{message}</Typography>}
    />
  )
}

export default FormErrorMessage
