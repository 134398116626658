import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { SetApplicationStatus, setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'
import {
  appFormShopRequestDataSelector,
  applicationFormCollateralsSelector,
  applicationFormIdSelector,
  applicationStatusSelector,
  currentClientPolicySelector
} from 'redux/reducers/client/allState/clientSelectors'
import {
  createClientCollateralThunk,
  getClientCollateralsThunk
} from 'redux/reducers/client/allState/clientThunks'
import { IClientCollateral } from 'redux/reducers/lender/allState/types/state'
import { handleFloatInput } from 'utils/common/forms'
import { DATE_FORMAT } from 'utils/getDate'

const CollateralPage: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { appFormFlowData } = useSelector(userFlowSelector)
  const userFlow = useSelector(userFlowSelector)
  const shopRequestData = useSelector(appFormShopRequestDataSelector)
  const status = useSelector(applicationStatusSelector)
  const localStorageAppId = localStorage.getItem('applicationId')
  const { list, status: collateralStatus } = useSelector(applicationFormCollateralsSelector)

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IClientCollateral>()

  useEffect(() => {
    if (!localStorageAppId) return
    dispatch(getClientCollateralsThunk(+localStorageAppId))
  }, [dispatch, localStorageAppId])

  const handleCreate = useCallback(() => {
    handleSubmit(data => {
      const id = userFlow.appFormFlowData?.flow_data.id
      if (id === undefined) {
        dispatch(setClientToastMessage({
          message: t('client.applicationForm.collateral.noId'),
          type: 'error'
        }))
        return
      }
      dispatch(createClientCollateralThunk(id, data, reset))
    })()
  }, [dispatch, handleSubmit, reset, t, userFlow.appFormFlowData?.flow_data.id])

  const handleNext = useCallback(() => {
    const flow_data = appFormFlowData?.flow_data
    if (flow_data) {
      dispatch(updateAppFormUserFlowThunk({ step: flow_data.step + 1 }))
    }
  }, [dispatch, appFormFlowData])

  const handlePrev = useCallback(() => {
    const flow_data = appFormFlowData?.flow_data
    if (flow_data) {
      dispatch(updateAppFormUserFlowThunk({ step: flow_data.step - 1 }))
    }
  }, [dispatch, appFormFlowData])

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column', mb: 2, gap: 2 }}>
        <Typography align="center" variant="h3">
          {t('client.applicationForm.collateral.title')}
        </Typography>
        <Controller
          control={control}
          name="collateral"
          rules={{ required: t('formValidation.required') }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <TextField
              disabled={collateralStatus === 'load'}
              value={value || ''}
              onChange={onChange}
              label={t('client.applicationForm.collateral.label.collateral')}
              select
              error={!!error}
              helperText={error?.message}
            >
              {shopRequestData!.credit_policy.collaterals.map(({ id, collateral_type: { name }, required }) => (
                <MenuItem key={id} value={id}>
                  {`${name}${required ? '*' : ''}`}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <TextField
          {...register('price', { required: t('formValidation.required') })}
          error={!!errors.price}
          helperText={errors.price?.message}
          label={t('client.applicationForm.collateral.label.price')}
          onChange={handleFloatInput}
          disabled={collateralStatus === 'load'}
        />
        <TextField
          {...register('description', {
            required: t('formValidation.required'),
            minLength: { value: 10, message: t('client.applicationForm.collateral.warn.length10') },
            maxLength: { value: 1000, message: t('client.applicationForm.collateral.warn.length1000') }
          })}
          error={!!errors.description}
          helperText={errors.description?.message}
          label={t('client.applicationForm.collateral.label.description')}
          multiline
          rows={3}
          disabled={collateralStatus === 'load'}
        />
        <Box sx={{ mb: 2 }}>
          <LoadingButton
            loading={collateralStatus === 'load'}
            onClick={handleCreate}
            variant="contained"
          >
            {t('client.applicationForm.collateral.create')}
          </LoadingButton>
        </Box>
      </Box>
      <TableContainer sx={{ mb: 2, flex: '1 1 auto' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('client.applicationForm.collateral.table.head.id')}</TableCell>
              <TableCell>{t('client.applicationForm.collateral.table.head.createdAt')}</TableCell>
              <TableCell>{t('client.applicationForm.collateral.table.head.updatedAt')}</TableCell>
              <TableCell>{t('client.applicationForm.collateral.table.head.price')}</TableCell>
              <TableCell>{t('client.applicationForm.collateral.table.head.description')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(({ id, created_at, updated_at, description, price }) => (
              <TableRow key={id}>
                <TableCell>{id}</TableCell>
                <TableCell>{moment(created_at).format(DATE_FORMAT)}</TableCell>
                <TableCell>{moment(updated_at).format(DATE_FORMAT)}</TableCell>
                <TableCell>{price}</TableCell>
                <TableCell>{description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <Button onClick={handlePrev} variant="contained">
          {t('client.applicationForm.collateral.button.prev')}
        </Button>
        <Button onClick={handleNext} variant="contained" disabled={list?.length === 0}>
          {t('client.applicationForm.collateral.button.next')}
        </Button>
      </Box>
    </Paper>
  )
}

export default CollateralPage
