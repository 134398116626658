import { useLocation } from 'react-router'

export const useSearchParams = () => {
  const location = useLocation()

  return (name: string): string => {
    const query = location.search.slice(1)
    const params = query.split('&').map(param => param.split('='))

    for (const [n, value] of params) {
      if (n === name) return value
    }

    return ''
  }
}
