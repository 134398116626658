import { createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'

import { axiosInstancePrivate } from 'api/api'
import TimeUnit, { TimeUnitType } from 'components/pageLender/productFormPage/ui/time/TimeUnit'
import { SelectElementType } from 'components/UI/select/types'
import { setLenderToastMessage } from 'redux/reducers/lender/allState/lenderReducer'
import { Commission, ICategory, IShop, ProductFormDataSchema } from 'redux/reducers/lender/productForm/types'

export const fetchProduct = createAsyncThunk<ProductFormDataSchema, number, { rejectValue: string }>(
  'lender/productForm/fetchProduct',
  async (id, thunkAPI) => {
    try {
      const productRes = await axiosInstancePrivate.get<any>(
        `lender/credit_policies/${id}/`
      )

      if (!productRes.data) {
        const errText = i18next.t('lender.creditPolicyForm.errors.noData')
        thunkAPI.dispatch(setLenderToastMessage({
          message: errText,
          type: 'error'
        }))
        return thunkAPI.rejectWithValue(errText)
      }

      const loanType = productRes.data.credit_type === 'Consumer finance'
        ? 'consumerFinance'
        : 'moneyOnTheCard'

      const countingType =
        productRes.data.overdue_interest_counting_type === 'From First Overdue Date'
          ? 'first'
          : 'last'

      const percentage = (value: any) => {
        if (typeof value === 'number') return value * 100
        return value
      }

      const toTimeUnit = (value: string | number) => {
        value = String(value)

        if (value === '1') return TimeUnit.Day('1')
        if (value === '2') return TimeUnit.Week('2')
        if (value === '3') return TimeUnit.Month('3')
        if (value === '4') return TimeUnit.Year('4')

        return TimeUnit.Day('1')
      }

      const allShopsRes = await axiosInstancePrivate.get<IShop[]>(
        'commerce/shops/'
      )

      const shopsRes = await axiosInstancePrivate.get<any[]>(
        `lender/credit_policies/${id}/shops/`
      )

      const shops: SelectElementType[] = allShopsRes.data.filter(sh1 =>
        shopsRes.data.find((sh2) =>
          sh1.id === sh2.shop
        )
      ).map(sh => ({
        value: String(sh.id),
        label: sh.name
      }))

      const allCategoriesRes = await axiosInstancePrivate.get<ICategory[]>(
        'commerce/categories/'
      )

      const categoriesRes = await axiosInstancePrivate.get<any[]>(
        `lender/credit_policies/${id}/categories/`
      )

      const categories: SelectElementType[] = allCategoriesRes.data.filter(c1 =>
        categoriesRes.data.find((c2) =>
          c1.id === c2.category
        )
      ).map(c => ({
        value: String(c.id),
        label: c.name
      }))

      const commissionsRes = await axiosInstancePrivate.get<any[]>(
        `lender/credit_policies/${id}/commissions/`
      )

      const commissions: Commission[] = commissionsRes.data.map(c => ({
        id: c.id,
        name: c.name,
        type: String(c.type),
        subtype: c.subtype,
        details: c.reason,
        commission: c.amount,
        frequency: c.periodicity === null ? undefined : String(c.periodicity),
        isNew: false,
        validationErrors: {}
      }))

      return {
        product: {
          name: productRes.data.name,
          merchantFee: productRes.data.merchant_fee,
          loanType,
          regions: productRes.data.regions.map((r: string): SelectElementType => ({
            value: r,
            label: r
          })),
          manualVerification: productRes.data.manual_verification,
          clientType: String(productRes.data.client_type),
          displayOnCalculator: productRes.data.visible_on_calculator,
          shops,
          categories,
          validationErrors: {}
        },
        issuance: {
          loanAmount: {
            min: productRes.data.loan_amount_limit_from,
            max: productRes.data.loan_amount_limit_to
          },
          loanTerm: {
            min: productRes.data.loan_term_limit_from,
            max: productRes.data.loan_term_limit_to,
            unit: TimeUnit.Day('1')
          },
          downPayment: {
            min: percentage(productRes.data.prepayment_min),
            max: percentage(productRes.data.prepayment_max),
            isAccepted: productRes.data.is_prepayment
          },
          allowGuarantor: productRes.data.is_guarantor_required,
          validationErrors: {
            downPayment: {},
            loanAmount: {},
            loanTerm: {}
          }
        },
        tranches: {
          min: productRes.data.min_tranches,
          max: productRes.data.max_tranches,
          isEnabled: productRes.data.is_tranches_enabled,
          canBeLess: productRes.data.disbursement_amount_more_approved,
          validationErrors: {}
        },
        revolvingLoan: {
          isEnabled: false,
          validationErrors: {}
        },
        creditDecision: {
          verificationType: productRes.data.manual_verification ? '2' : '1',
          verifierType: String(productRes.data.verifier_type.id),
          validationErrors: {}
        },
        interestAccrual: {
          interestRate: percentage(productRes.data.interest),
          calcMethod: productRes.data.interest_method,
          repaymentType: String(productRes.data.repayment_frequency_value),
          repaymentInterval: productRes.data.repayment_frequency_n,
          repaymentIntervalUnit: toTimeUnit(productRes.data.repayment_frequency_unit),
          daysInYear: String(productRes.data.percent_accrual_schema),
          gracePeriod: productRes.data.interest_delay_value,
          graceUnit: toTimeUnit(productRes.data.interest_delay_unit),
          interestGrace: 'onDisbursement',
          validationErrors: {}
        },
        overdueInterestAccrual: {
          interestRate: percentage(productRes.data.overdue_interest),
          countingType,
          gracePeriod: productRes.data.overdue_interest_delay_value,
          gracePeriodUnit: toTimeUnit(productRes.data.overdue_interest_delay_unit),
          interestPeriodUnit: toTimeUnit(productRes.data.overdue_interest_delay_unit),
          validationErrors: {}
        },
        repaymentScheduleManagement: {
          principleRepayment: {
            min: 5,
            max: 10
          },
          loanExtension: {
            min: 5,
            max: 10
          },
          repaymentDate: {
            min: 5,
            max: 10
          },
          paymentHoliday: {
            min: 5,
            max: 10
          },
          validationErrors: {
            principleRepayment: {},
            loanExtension: {},
            repaymentDate: {},
            paymentHoliday: {}
          }
        },
        commissions,
        collaterals: productRes.data.collaterals.map((c: any) => ({
          id: c.id,
          isRequired: c.required,
          description: String(c.collateral_type.id),
          frequency: c.monitoring_frequency,
          periodUnit: TimeUnit.Day('1'),
          validationErrors: {}
        }))
      }
    } catch (e: any) {
      const msg: string = e.message

      thunkAPI.dispatch(setLenderToastMessage({
        message: msg,
        type: 'error'
      }))

      return thunkAPI.rejectWithValue(msg)
    }
  })
