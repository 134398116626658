import {
  Box, Button, FormControl, TextField
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import React, { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormErrorMessage from 'components/common/formErrorMessage/formErrorMessage'
import { IShopRequestData } from 'redux/reducers/client/allState/types/stateTypes'

interface IProps {
  handleClose: () => void
  applicationId: number
  tranchesList: any
  setTranchesList: (prev: any) => any
  shopRequestData: IShopRequestData | null
  summary: {
    amount: number
  }
}

const TranchesModal: FC<IProps> = ({
  handleClose,
  applicationId,
  tranchesList,
  setTranchesList,
  shopRequestData,
  summary
}) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      application: applicationId
    }
  })

  const disableDate = (dateChange?: any) => {
    const date = dateChange ? new Date(dateChange) : new Date()
    date.setDate(date.getDate() + 1)
    return moment(date).format('YYYY-MM-DD')
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    const parseData = {
      ...data,
      date: moment(data.date).format('YYYY-MM-DD')
    }

    setTranchesList((prev: any) => {
      if (prev?.length !== 0) {
        const temp = [...prev]
        temp.push(parseData)

        return temp
      }
      return [parseData]
    })

    handleClose()
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', mb: 4, pt: 1 }}>
          {shopRequestData?.application?.loan_amount && (
            <FormControl sx={{ mb: 4, width: '100%' }}>
              <TextField
                type="number"
                sx={{ width: '100%' }}
                label={t('client.applicationForm.tranches.modal.amount')}
                fullWidth
                helperText={t('formValidation.max', { n: shopRequestData?.application?.loan_amount - summary.amount })}
                {...register('amount', {
                  required: { value: true, message: t('formValidation.required') },
                  max: {
                    value: shopRequestData?.application?.loan_amount - summary.amount,
                    message: t('formValidation.max', { n: shopRequestData?.application?.loan_amount - summary.amount })
                  }
                })}
              />
              <FormErrorMessage errors={errors} name='amount' />
            </FormControl>
          )}
          <Box sx={{ width: '100%' }}>
            <Controller
              control={control}
              name={'date'}
              defaultValue={null}
              rules={{
                required: {
                  value: true,
                  message: t('formValidation.required')
                }
              }}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  label={t('client.applicationForm.tranches.modal.date')}
                  value={value}
                  minDate={tranchesList?.length !== 0 ? disableDate(tranchesList[tranchesList?.length - 1]?.date) : disableDate()}
                  onChange={date => onChange(date)}
                  renderInput={params => <TextField fullWidth {...params} />}
                />
              )}
            />
            <FormErrorMessage errors={errors} name='date' />
          </Box>
        </Box>

        <Box mt={2} display='flex' alignItems='center' justifyContent='space-between'>
          <Button variant="outlined" onClick={handleClose}>
            {t('common.buttons.close')}
          </Button>

          <Button variant="contained" type="submit">
            {t('common.buttons.add')}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default TranchesModal
