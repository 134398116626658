import { createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'

import { axiosV2InstancePrivate } from 'api/api'
import { setLenderToastMessage } from 'redux/reducers/lender/allState/lenderReducer'
import { IScoreCardQuery } from 'redux/reducers/lender/scoring/types'

export const fetchQueries = createAsyncThunk(
  'lender/scoring/fetchQueries',
  async (policyId: number, thunkAPI) => {
    try {
      const res = await axiosV2InstancePrivate
        .get<IScoreCardQuery[]>(`lender/credit_policies/${policyId}/scorecard/queries/`)

      if (!res.data) {
        thunkAPI.dispatch(setLenderToastMessage(i18next.t('lender.decisionEngine.rulePage.toast.fetchQueriesNoData')))
        throw new Error()
      }

      return res.data
    } catch (e: any) {
      thunkAPI.dispatch(setLenderToastMessage({ type: 'error', message: e.message }))
      thunkAPI.rejectWithValue(e.message)
    }
  })
