import { Paper } from '@mui/material'
import React, { useState } from 'react'

import { wavesSVG } from 'components/pageHome/ui/wavesSVG'
import { translateType } from 'translations/types'

import cls from '../AuthPages.module.scss'
import AuthTitle from '../ui/authTitle'

import SignUpForm from './SignUpForm/SignUpForm'
import SignUpVerification from './SignUpForm/SignUpVerification'

interface IProps {
  t: translateType
}

const SignUpPage: React.FC<IProps> = ({ t }) => {
  const [formStatus, setFormStatus] = useState({ isSubmitted: false, isSuccessful: false })
  const [email, setEmail] = useState('')

  return (
    <Paper className={cls.paper}>
      <AuthTitle login title={t('homePage.signUp.titleSign')}/>
      {formStatus.isSuccessful
        ? <SignUpVerification t={t} email={email}/>
        : <SignUpForm t={t} setFormStatus={setFormStatus} formStatus={formStatus} setEmail={setEmail}/>}
      { wavesSVG(cls.waves) }
    </Paper>
  )
}

export default SignUpPage
