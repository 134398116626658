import { translateType } from 'translations/types'

const getAuthValidation = (t: translateType) => {
  return {
    email: {
      required: t('formValidation.required')
    },
    password: {
      required: t('formValidation.required'),
      minLength: {
        value: 8,
        message: t('formValidation.minLength', { n: 8 })
      }
    }
  }
}

export const getValidationByFieldType = (type: string, t: translateType) => {
  switch (type) {
    case 'tel': {
      return {
        value: /(380)\d{9}/,
        message: t('formValidation.pattern')
      }
    }
    case 'email': {
      return {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: t('formValidation.email')
      }
    }
  }
}

export default getAuthValidation
