export const commerceActions = {
  SET_FETCHING_COMMERCE_DATA: 'SET_FETCHING_COMMERCE_DATA',

  GET_APPLICATIONS: 'GET_APPLICATIONS',
  GET_APPLICATIONS_COUNT: 'GET_APPLICATIONS_COUNT',

  SET_SHOP_CATEGORIES: 'SET_SHOP_CATEGORIES',
  SET_SHOPS: 'SET_SHOPS',

  SET_PRODUCT_LINK: 'SET_PRODUCT_LINK',

  SET_SELECTED_SHOP: 'SET_SELECTED_SHOP',
  SET_INVITATIONS_COUNT: 'SET_INVITATIONS_COUNT',
  SET_INVITATION_STATS: 'SET_INVITATION_STATS',
  SET_INVITE_MEMBER_DIALOG: 'INVITE_MEMBER_DIALOG',

  SET_INVOICE_STATUS: 'SET_INVOICE_STATUS',
  SET_INVOICE_MODAL_STATUS: 'SET_INVOICE_MODAL_STATUS',
  SET_INVOICE_APPLICATION_ID: 'SET_INVOICE_APPLICATION_ID',

  SET_COMMERCE_TOAST_MESSAGE: 'SET_COMMERCE_TOAST_MESSAGE',

  SET_APPLICATION_SHIPPING_STATUS: 'SET_APPLICATION_SHIPPING_STATUS'
} as const
