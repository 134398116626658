import { v4 as uuidv4 } from 'uuid'

type WithId<T> = T & {
  id: string
}

export function getNewDataWithId<T> (data: T): WithId<T> {
  const id = uuidv4()
  return { ...data, id }
}
