import { SvgIcon, SvgIconProps } from '@mui/material'

const ConstructorSelectArrowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 17 11">
      <path d="M8.5 11L16.7272 0.5H0.272758L8.5 11Z" fill="#C4C4C4" />
    </SvgIcon>
  )
}

export default ConstructorSelectArrowIcon
