import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import passwordIcon from 'assets/images/icons/recoverPasswordIcon.png'
import { colors } from 'config'

interface IProps {
  title: string
  login?: boolean
  password?: boolean
}

const styles = {
  icon: {
    display: 'block',
    margin: '0 auto',
    width: '58px',
    height: '58px',
    color: colors.darkBlue
  },
  passwordIcon: {
    display: 'block',
    margin: '0 auto 10px',
    width: '42px',
    height: 'auto'
  }
}

const AuthTitle: React.FC<IProps> = ({ title, login, password }) => {
  const { t } = useTranslation()
  return (
    <>
      <Box>
        {login && <PersonRoundedIcon sx={styles.icon}/>}
        {password && <img src={passwordIcon} style={styles.passwordIcon} alt="recover password"/>}
      </Box>
      <Typography variant="h1" align="center" mb={4}>{t(title)}</Typography>
    </>
  )
}

export default AuthTitle
