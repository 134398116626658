import { createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'

import { axiosInstancePrivate } from 'api/api'
import { setLenderToastMessage } from 'redux/reducers/lender/allState/lenderReducer'

export const getTemplate = createAsyncThunk(
  'lender/scoring/getTemplate',
  async (uuid: string, thunkAPI) => {
    try {
      const res = await axiosInstancePrivate.get<any>(`questionnaries/lender/template-retrieve/${uuid}/`)

      if (!res.data || !res.data.elements?.steps) {
        return thunkAPI.rejectWithValue('no data')
      }

      res.data.steps = res.data.elements.steps

      if (!res.data.steps.length) {
        thunkAPI.dispatch(setLenderToastMessage({
          message: i18next.t('lender.decisionEngine.rulePage.toast.templateWithoutSteps'),
          type: 'warning'
        }))
      }

      return res.data
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.message)
    }
  })
