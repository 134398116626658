import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

const SignUpConfirmationPage: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const queryProps = location.search.split('status=')[1].split('&message=')
    setStatus(queryProps[0].replaceAll('%20', ' '))
    setMessage(queryProps[1].replaceAll('%20', ' '))
  }, [])

  return (
    <>
      <Typography variant="h5" align="center" mb={4}>
        {status === 'success' ? t('homePage.signUpConfirmation.success') : t('homePage.signUpConfirmation.error')}
      </Typography>
      <Typography align="center" mb={2}>{message}</Typography>
      {status === 'success' && <Typography align="center">{t('homePage.signUpConfirmation.title')}</Typography>}
    </>
  )
}

export default SignUpConfirmationPage
