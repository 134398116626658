import { AxiosResponse } from 'axios'

import { axiosInstancePrivate } from 'api/api'
import {
  IApplication,
  IApplicationDocument,
  IGuarantor,
  IGuarantorModel,
  IPaymentScheduleTodayPayment,
  IQuestionaryResponse,
  IShopRequestData, TApplicationDocument,
  TGuarantorModelStatus
} from 'redux/reducers/client/allState/types/stateTypes'
import {
  IClientCollateral,
  IClientCollateralResponse,
  IClientTranches,
  IClientTranchesResponse,
  IUserFlow,
  IUserFlowData
} from 'redux/reducers/lender/allState/types/state'
import { IPaymentGraphic } from 'types/client/ApplicationForm'
import { getCookie } from 'utils/common/apiHelpers'

export const clientApi = {
  applicationForm: {
    getShopRequest: (uuid: string): Promise<AxiosResponse<IShopRequestData>> =>
      axiosInstancePrivate.get(`api_integration/shop_request/${uuid}/`),
    getManualVerification: () => {
      return axiosInstancePrivate('lender/credit_policies').catch(err => err.response)
    },
    getManualVerificationById: (id: number) => {
      return axiosInstancePrivate(`lender/credit_policies/${id}`).catch(err => err.response)
    },
    getCurrentQuestionary: (policyId: number) => {
      return axiosInstancePrivate(`lender/credit_policies/${policyId}/questionaries/`).catch(
        err => err.response
      )
    },
    sendQuestionaryFormFirstRequest: (
      policyId: number,
      questionaryId: number,
      data: any
    ): Promise<AxiosResponse<IQuestionaryResponse>> =>
      axiosInstancePrivate.post(
        `lender/credit_policies/${policyId}/questionaries/${questionaryId}/completed_questionaries/`,
        { json_data: { ...data } }
      ),
    sendRecurringPayment: (application_id: number) => {
      return axiosInstancePrivate.patch(`/client/applications/${application_id}/`, {
        recurring_payment: true
      })
    },
    postApplication: (data: any): Promise<AxiosResponse<{ id: number }>> =>
      axiosInstancePrivate.post('client/applications/', {
        ...data,
        checks: {
          proxy_check: false
        }
      }),
    sendDataCard: (
      user_id: number,
      application_id: number,
      card_number: string,
      cardholder_name: string
    ) => {
      return axiosInstancePrivate
        .post(`/client/${user_id}/bank_card/`, {
          application_id,
          card_number,
          cardholder_name
        })
        .catch(err => err.response)
    },
    changeStatus: (id: number, status: number) => {
      return axiosInstancePrivate
        .patch(`/client/applications/${id}/`, {
          status
        })
        .catch(err => console.error(err))
    },
    changeDateInterestStart: (id: number, date_interest_start: string) => {
      return axiosInstancePrivate
        .patch(`/client/applications/${id}/`, {
          date_interest_start
        })
        .catch(err => console.error(err))
    },
    changeUseTor: (id: number, tor: boolean) => {
      return axiosInstancePrivate
        .patch(`/client/applications/${id}/`, {
          checks: {
            tor
          }
        })
        .catch(err => console.error(err))
    },
    getClientApplicationsById: (id: number): Promise<AxiosResponse<IApplication>> => {
      return axiosInstancePrivate.get(`/client/applications/${id}/`)
    },
    getContract: (contract_id: string | number) => {
      return axiosInstancePrivate(`client/contracts/${contract_id}`, {
        headers: {
          Authorization: `Token ${getCookie('token')}`
        },
        responseType: 'blob'
      }).catch(err => err.response)
    },
    getClientDocumentByAppId: (appId: number, typeDocument: TApplicationDocument): Promise<AxiosResponse<IApplicationDocument[]>> =>
      axiosInstancePrivate.get(`client/applications/${appId}/documents/?document_type=${typeDocument}`),
    getClientDocumentByDocIdFile: (docId: number): Promise<AxiosResponse<Blob>> =>
      axiosInstancePrivate.get(`client/documents/${docId}/download/`, {
        responseType: 'blob'
      }),
    getSMSCode: (id: number) => {
      return axiosInstancePrivate
        .post('smsclub/send_sms/', { application_id: id })
        .catch(err => err.response)
    },
    checkSMSCode: (application_id: number, sms_code: string) => {
      return axiosInstancePrivate
        .post('smsclub/check_sms/', { application_id, sms_code })
        .catch(err => err.response)
    },
    completeForm: (application_id: number, status: number): Promise<AxiosResponse<IApplication>> =>
      axiosInstancePrivate.patch(`client/applications/${application_id}/`, { status }),
    cancelApplication: (application_id: number) => {
      return axiosInstancePrivate
        .patch(`client/applications/${application_id}/`, { status: 10 })
        .catch(err => err.response)
    },
    getClientCollateral: (appId: number): Promise<AxiosResponse<IClientCollateralResponse[]>> =>
      axiosInstancePrivate.get(`client/applications/${appId}/collaterals/`),
    postClientCollateral: (
      appId: number,
      data: IClientCollateral
    ): Promise<AxiosResponse<IClientCollateralResponse>> =>
      axiosInstancePrivate.post(`client/applications/${appId}/collaterals/`, data),
    deleteUserFlow: (
      flowId: number
    ): Promise<AxiosResponse<IClientCollateralResponse>> =>
      axiosInstancePrivate.delete(`users/user_flow/${flowId}/`),
    getAutofillAnswers: () => {
      return axiosInstancePrivate('client/autofill_answers/')
        .catch(err => err.response)
    },
    getTranchesApplication: (application_id: number) => {
      return axiosInstancePrivate('tranches/client/', {
        params: {
          application: application_id
        }
      })
        .catch(err => err.response)
    },
    postTranchesApplication: (application_id: number, data: IClientTranches[]): Promise<AxiosResponse<IClientTranchesResponse[]>> => {
      return axiosInstancePrivate.post('tranches/client/', data)
    }
  },
  applications: {
    getApplications: () => axiosInstancePrivate('client/applications/').catch(err => err.response),

    patchApplicationById: (
      applicationId: number,
      type: string,
      data: any
    ): Promise<AxiosResponse<IApplication>> =>
      axiosInstancePrivate.patch(`client/applications/${applicationId}/`, { [type]: data }),

    patchApplication: (
      id: number,
      data: any
    ): Promise<AxiosResponse<IApplication>> =>
      axiosInstancePrivate.patch(`client/applications/${id}/`, data),

    getApplication: (id: number): Promise<AxiosResponse<IApplication>> =>
      axiosInstancePrivate(`client/applications/${id}`).catch(err => err.response),

    getApplicationsStatistics: (query: string) =>
      axiosInstancePrivate(`api/client/applications/statistic/?${query}`),

    postUserFlowData: (data: IUserFlowData): Promise<AxiosResponse<IUserFlow>> =>
      axiosInstancePrivate.post('users/user_flow/', { flow_data: data }),

    patchUserFlowData: (id: number, flow_data: IUserFlowData): Promise<AxiosResponse<IUserFlow>> =>
      axiosInstancePrivate.patch(`users/user_flow/${id}/`, { flow_data }),
    deleteUserFlowData: (id: number): Promise<AxiosResponse> =>
      axiosInstancePrivate.delete(`users/user_flow/${id}/`),
    postPaymentScheduleSendCode: (approvedRepaymentChangeId: number, sms_code: string): Promise<AxiosResponse<any>> =>
      axiosInstancePrivate.post(`payment-schedule/${approvedRepaymentChangeId}/sign/`, { sms_code }),
    getPaymentGraphic: (credit_policy_id: number, amount: number, loan_term: number, category_id: number, promo_code: string | null): Promise<AxiosResponse<IPaymentGraphic[]>> => {
      return axiosInstancePrivate.post(
        'payment-schedule/calculate/', {
          credit_policy_id, amount, loan_term, category_id, promo_code
        })
        .catch(err => err.response)
    },
    getPaymentScheduleTodayPayment: (application_id: number): Promise<AxiosResponse<IPaymentScheduleTodayPayment>> =>
      axiosInstancePrivate.get(`payment-schedule/${application_id}/today-payment/`),

    getTranches: (id: number) => axiosInstancePrivate(`client/applications/${id}/tranches/`).catch(err => err.response),
    getTransactions: (id: number) => axiosInstancePrivate(`client/applications/${id}/transactions/`).catch(err => err.response)
  },
  guarantor: {
    postRegistery: (data: IGuarantor, uuid: string): Promise<AxiosResponse<IGuarantor>> =>
      axiosInstancePrivate.post(`users/guarantors/${uuid}/register/`, data),

    getApplications: (): Promise<AxiosResponse<IApplication[]>> =>
      axiosInstancePrivate.get('client/applications/guarantor_applications/'),

    getModels: (): Promise<AxiosResponse<IGuarantorModel[]>> =>
      axiosInstancePrivate.get('client/guarantors/'),

    getContractByModelUuid: (modelUuid: string): Promise<AxiosResponse<Blob>> =>
      axiosInstancePrivate.get(`client/contracts/guarantor/by_guarantor_id/${modelUuid}/`, {
        responseType: 'blob'
      }),

    postModel: (data: IGuarantorModel): Promise<AxiosResponse<IGuarantorModel>> =>
      axiosInstancePrivate.post('client/guarantors/', data),

    getSMSCode: (uuid: string): Promise<AxiosResponse<string>> =>
      axiosInstancePrivate.post('smsclub/send_guarantor_sms/', { guarantor_id: uuid }),

    checkSMSCode: (uuid: string, sms_code: string): Promise<AxiosResponse> =>
      axiosInstancePrivate.post('smsclub/check_guarantor_sms/', { guarantor_id: uuid, sms_code }),

    patchModelStatus: (
      uuid: string,
      status: TGuarantorModelStatus
    ): Promise<AxiosResponse<IGuarantorModel>> =>
      axiosInstancePrivate.patch(`client/guarantors/${uuid}/`, { status })
  }
}
