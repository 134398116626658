import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  styled,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { clientApi } from 'api/clientApi'
import BankIdLogo from 'assets/images/bankId.png'
import FormErrorMessage from 'components/common/formErrorMessage/formErrorMessage'
import AppPreloader from 'components/common/preloaders/appPreloader'
import { fetchBankIdRequests } from 'components/pageClient/applicationForm/4_BankCardPage/fetchBankIdRequests'
import { fetchBankIdUrl } from 'components/pageClient/applicationForm/4_BankCardPage/fetchBankIdUrl'
import { BASE_URL, COUNTRY } from 'config'
import { setAppFormUserFlow } from 'redux/reducers/auth/authReducer'
import { cardStepSettingsSelector, currentUserSelector, userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import { setClientToastMessage } from 'redux/reducers/client/allState/clientReducer'
import { appFormCreditPolicyData } from 'redux/reducers/client/allState/clientSelectors'
import { deleteUserFlow } from 'redux/reducers/client/allState/clientThunks'
import { getErrorMessage } from 'utils/getErrorMessage'

const BankCardForm: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { appFormFlowData } = useSelector(userFlowSelector)
  const currentUser = useSelector(currentUserSelector)
  const creditPolicy = useSelector(appFormCreditPolicyData)
  const settings = useSelector(cardStepSettingsSelector)

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' })

  const [requestProcessing, setRequestProcessing] = useState(false)
  const [showErrorMassageBankId, setShowErrorMassageBankId] = useState(false)
  const [showBankId, setShowBankId] = useState(false)
  const [checkBankId, setCheckBankId] = useState(false)

  const handlePrev = useCallback(() => {
    if (appFormFlowData) {
      dispatch(updateAppFormUserFlowThunk({ step: appFormFlowData.flow_data.step - 1 }))
    }
  }, [appFormFlowData, dispatch])

  const getCurrentStatusId = useCallback(async (stepNext: boolean = true) => {
    try {
      if (creditPolicy) {
        if (settings?.active && stepNext) {
          const appResUpd = await clientApi.applications.getApplication(appId)

          if (appResUpd.status === 200) {
            const appUpdStatusId = appResUpd.data.status.id

            if (appUpdStatusId === 11) {
              // console.log(appUpdStatusId)
            }

            // Андеррайтинг
            if (appUpdStatusId === 2 && stepNext) {
              const isFlowUpdate = await dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))
              // @ts-ignore
              if (isFlowUpdate) history.replace('/client/active-loans')
            }

            // Підписання договору
            if (((appUpdStatusId === 4) || (appUpdStatusId === 14)) && stepNext) {
              dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))
            }
          }
          return
        }
        setRequestProcessing(true)
        // 2 step - отримуємо поточний status.id заявки
        const appRes = await clientApi.applications.getApplication(appId)

        if (appRes.status === 200) {
          const appCurrentStatusId = appRes.data.status.id
          let statusIdToUpdate = 4

          if (appCurrentStatusId !== 2 && creditPolicy?.manual_verification) {
            statusIdToUpdate = 2
          }

          // на який статус патчити якщо тільки гарантор?
          if (creditPolicy?.is_guarantor_required) {
            //
          }
          clientApi.applicationForm.changeStatus(appId, statusIdToUpdate).then(() => {
            setTimeout(async () => {
              const appResUpd = await clientApi.applications.getApplication(appId)

              if (appResUpd.status === 200) {
                const appUpdStatusId = appResUpd.data.status.id

                if (appUpdStatusId === 11) {
                  // console.log(appUpdStatusId)
                }

                // Андеррайтинг
                if (appUpdStatusId === 2 && stepNext) {
                  const isFlowUpdate = await dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))
                  // @ts-ignore
                  if (isFlowUpdate) history.replace('/client/active-loans')
                }

                // Підписання договору
                if (((appUpdStatusId === 4) || (appUpdStatusId === 14)) && stepNext) {
                  dispatch(updateAppFormUserFlowThunk({ step: step + 1 }))
                }
                // Reject
                if (appUpdStatusId === 9) {
                  history.replace('/client/active-loans')
                  if (appFormFlowData) {
                    dispatch(deleteUserFlow(appFormFlowData.id, false))
                  }
                  dispatch(setAppFormUserFlow(null))
                }
              }

              setRequestProcessing(false)
            }, 4000)
          })
        }
      }
    } catch (err: any) {
      dispatch(
        setClientToastMessage({ type: 'error', message: getErrorMessage(err.responce.data) })
      )
    }
  }, [creditPolicy, settings])

  useEffect(() => {
    if (settings?.active && creditPolicy) {
      getCurrentStatusId(false)
    }
  }, [settings, creditPolicy])

  useEffect(() => {
    (async () => {
      if (!appFormFlowData) return
      if (BASE_URL === 'https://vse-v-kredit.com.ua') return
      if (COUNTRY === 'Ukraine') {
        const res = await fetchBankIdRequests(appFormFlowData?.flow_data.id)

        // @ts-ignore
        if ((res.status === 200 || res.status === 401) && (res.data?.customer_crypto === null || res.data?.customer_crypto?.length === 0) && (res.data?.state !== null || res.data?.state?.length === 0)) {
          setShowErrorMassageBankId(true)
          return
        }

        if ((res.status === 200 || res.status === 401) && res.data?.state !== null && (res.data?.customer_crypto === null || res.data?.customer_crypto?.length === 0)) {
          return
        }
        if ((res.status === 200 || res.status === 401) && res.data?.state === null) {
          setShowBankId(true)
          return
        }
      }

      setShowBankId(false)
    })()
  }, [appFormFlowData])

  if (requestProcessing) {
    return <Box mt={5} textAlign="center">
      <AppPreloader />
      {t('client.applicationForm.bankCard.pleaseWait')}
    </Box>
  }

  if (!appFormFlowData || !creditPolicy || !currentUser) return <Typography>{t('client.applicationForm.bankCard.noData')}</Typography>

  const {
    flow_data: { id: appId, step }
  } = appFormFlowData

  const onSubmit = async (data: any) => {
    try {
      // 1 step - надсилаємо запит на `/client/${user_id}/bank_card/`
      const res = await clientApi.applicationForm.sendDataCard(
        currentUser.id,
        appId,
        creditPolicy.credit_type === 'Consumer finance' ? '4111111111111111' : data.card_number,
        creditPolicy.credit_type === 'Consumer finance' ? 'Test' : data.cardholder_name
      )

      if (res.status === 201) {
        await getCurrentStatusId(true)
      }

      if (res.status === 400) {
        dispatch(
          setClientToastMessage({ type: 'error', message: getErrorMessage(res.data) })
        )
      }
    } catch (err: any) {
      dispatch(
        setClientToastMessage({ type: 'error', message: getErrorMessage(err.responce.data) })
      )
    }
  }

  const handleBankIdClick = async () => {
    await fetchBankIdUrl(appId).then((r) => {
      if (r.error) {
        dispatch(setClientToastMessage({
          message: r.error,
          type: 'error'
        }))
      }

      if (r.url) {
        window.location.href = r.url
      }
    })
  }

  return (
    <>
      {showErrorMassageBankId && (
        <FlexBox>
          <Typography sx={{ color: 'red' }}>
            Виникла помилка при авторизації через BankID. Будь ласка, зв'яжіться з нами
          </Typography>
        </FlexBox>
      )}
      {
        showBankId && (
          <FlexBox>
            <Tooltip title="Для Ідентифікації натисніть на зображення BankID" placement="top" open={checkBankId} arrow>
              <ImageBox
                onClick={async () => {
                  if (checkBankId) {
                    await handleBankIdClick()
                  } else {
                    dispatch(setClientToastMessage({ type: 'error', message: t('formValidation.iban', { n: 50 }) }))
                  }
                }}
              >
                <img src={BankIdLogo} alt={'Bank Id'}/>
              </ImageBox>
            </Tooltip>
            <Typography>
              {t('client.applicationForm.bankCard.bankIdText')}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Checkbox
                checked={checkBankId}
                onChange={() => setCheckBankId(prev => !prev)}
              />
              <Typography sx={{ maxWidth: '800px' }}>
                Даю згоду на обробку персональних даних отриманих через систему BankID НБУ. Передача даних з системи BankID НБУ безкоштовна. Перелік полів, які будуть передаватися зазначені нижче
              </Typography>
            </Box>

            <Box sx={{
              '.MuiAccordionSummary-gutters': { margin: '0 auto' }
            }}>
              <Accordion
                sx={{
                  boxShadow: 'none !important',
                  backgroundColor: '#fff !important',
                  marginBottom: '0 !important',
                  '& .MuiAccordionSummary-content': {
                    margin: '0 5px 0 0'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  sx={{ width: 'max-content' }}
                >
                  Натисніть, щоб побачити перелік полів
                </AccordionSummary>
                <AccordionDetails sx={{ padding: '0 16px !important' }}>
                  Прізвище.
                  <br />
                  Ім`я.
                  <br />
                  По-батькові.
                  <br />
                  Номер (и) контактного телефону.
                  <br />
                  Реєстраційний номер облікової картки платника податків, номер (та за наявності - серію) паспорта громадянина України, в якому проставлено відмітку про відмову від прийняття реєстраційного номера облікової картки платника податків, чи номер паспорта із записом про відмову від прийняття реєстраційного номера облікової картки платника податків в електронному безконтактному носії (для нерезидентів заповнюється за наявності).
                  <br />
                  Унікальний ідентифікатор особи (клієнта) в банку. У випадку якщо банк не має такого ідентифікатора, можливо вказати значення ключа inn або серію і номер паспорта.
                  <br />
                  Статичний текст з інформацією про надані дані Абонентом-ідентифікатором щодо особи, дата і час надання. “Інформація надана з використанням Системи BankID НБУ dd.mm.yyyy hh.mm”
                  <br />
                  Дата народження.
                  <br />
                  Місце народження (за наявності).
                  <br />
                  Громадянство.
                  <br />
                  Стать.
                  <br />
                  Адреса електронної пошти.
                  <br />
                  Соціальний статус.
                  <br />
                  Місце роботи.
                  <br />
                  Посада.
                  <br />
                  Ознака, чи визначена особа повіреною Абонентом-ідентифікатором такою, що належить до категорії PEPs (публічно значущі особи, члени сім’ї, пов’язані).
                  <br />
                  Ознака, чи визначена особа повіреною Абонентом-ідентифікатором такою, що включена до переліку осіб, пов’язаних зі здійсненням терористичної діяльності або щодо яких застосовано міжнародні санкції.
                  <br />
                  Ознака, чи визначена особа повіреною Абонентом-ідентифікатором такою, що включена до переліку осіб, щодо яких застосовані персональні, спеціальні економічні та інші обмежувальні заходи (санкції), санкції РНБОУ.
                  <br />
                  Ознака, чи присвоєно особі повіреною Абонентом-ідентифікатором (неприйнятно) високий рівень ризику ПВК/ФТ.
                  <br />
                  Ознака, чи визначена особа повіреною Абонентом-ідентифікатором такою, що є резидентом України.
                  <br />
                  Дата встановлення або дата зміни фінансового номеру телефону в системах Абонента-ідентифікатора.
                  <br />
                  Дата проходження ідентифікації особою або дата останнього уточнення інформації про нєї.
                  <br />
                  Тип адреси проживання: factual – фактична адреса проживання (місце перебування); juridical – адреса реєстрації (місце проживання).
                  <br />
                  Країна проживання/реєстрації.
                  <br />
                  Поштовий індекс.
                  <br />
                  Область.
                  <br />
                  Район.
                  <br />
                  Назва населеного пункту.
                  <br />
                  Тип вулиці (наприклад: вулиця, узвіз, проспект і тд) та її назва Якщо адреса користувача не передбачає наявності типу вулиці (наприклад: вулиця, узвіз, проспект і тд) та її назви, необхідно передавати значення 'n/a'.
                  <br />
                  Номер будинку (і за наявності літера будинку та/або номер корпусу/блоку/секції). Якщо адреса користувача не передбачає наявності номеру будинку, необхідно передавати значення 'n/a'.
                  <br />
                  Номер квартири (і за наявності літера квартири).
                  <br />
                  Тип документу: passport – паспорт громадянина України; idpassport – id-картка; zpassport – паспорт для виїзду за кордон; ident – інший документ, що посвідчує особу та відповідно до законодавства України може бути використаний на території України для укладення правочинів.
                  <br />
                  Назва документу
                  <br />
                  Серія документа (для типу idpassport – не заповнюється, для осіб - нерезидентів заповнюється за наявності серії в їх документах).
                  <br />
                  Номер документа
                  <br />
                  Яким органом видано документ
                  <br />
                  Дата видачі документу.
                  <br />
                  Дата закінчення строку дії (для типу passport - не заповнюється).
                  <br />
                  Унікальний номер запису в Єдиному державному демографічному реєстрі.
                  <br />
                  Країна видачі документа.
                </AccordionDetails>
              </Accordion>
            </Box>

          </FlexBox>
        )
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        {
          !showBankId && (
            <>

              <Box
                mb={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <TextField
                  style={{ marginBottom: '20px' }}
                  variant="standard"
                  label={COUNTRY === 'Ukraine' ? t('client.applicationForm.bankCard.bankCard') : t('client.applicationForm.bankCard.iban')}
                  {...register('card_number', {
                    required: t('formValidation.required'),
                    minLength: {
                      value: 16,
                      message: t('formValidation.minLength', { n: 16 })
                    },
                    maxLength: {
                      value: 16,
                      message: t('formValidation.maxLength', { n: 16 })
                    }
                  })}
                  type="text"
                />
                <FormErrorMessage errors={errors} name="card_number" />
                {/* <InputMask mask={'9999 9999 9999 9999'} maskChar={'-'} {...register('card_number')}> */}
                {/*  {() => ( */}
                {/*    <TextField */}
                {/*      style={{ marginBottom: '20px' }} */}
                {/*      variant="standard" */}
                {/*      label={t('client.applicationForm.bankCard.iban')} */}
                {/*      {...register('card_number')} */}
                {/*      type="text" */}
                {/*    /> */}
                {/*  )} */}
                {/* </InputMask> */}
                <TextField
                  variant="standard"
                  label={COUNTRY === 'Ukraine' ? t('client.applicationForm.bankCard.holder') : t('client.applicationForm.bankCard.taxNumber')}
                  {...register('cardholder_name', {
                    required: t('formValidation.required')
                  })}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const string = event.currentTarget.value
                    const arrayString = string?.split(' ')?.filter((value: string) => value !== '')

                    if (arrayString?.length > 2) {
                      event.currentTarget.value = arrayString?.join(' ')
                    }
                  }}
                  type="text"
                />
                <FormErrorMessage errors={errors} name="cardholder_name" />
              </Box>
              <Box>
                <ul>
                  <h4>
                    {t('client.applicationForm.bankCard.agreeAndCertify')}
                  </h4>
                  <li>
                    {t('client.applicationForm.bankCard.consentToReceive')}
                    {t('client.applicationForm.bankCard.representatives')}
                  </li>
                  <li>
                    {t('client.applicationForm.bankCard.certify')}
                  </li>
                  <li>
                    {t('client.applicationForm.bankCard.submitting')}
                  </li>
                  <li>
                    {t('client.applicationForm.bankCard.understand')}
                  </li>
                </ul>
              </Box>
            </>
          )
        }
        <Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2
          }}>
            <Button onClick={handlePrev} variant="contained" disabled={showErrorMassageBankId}>
              {t('client.applicationForm.bankCard.buttonPrev')}
            </Button>
            <Button type="submit" variant="contained" disabled={showBankId || showErrorMassageBankId}>
              {t('common.buttons.save')}
            </Button>
          </Box>
        </Box>
      </form>
    </>
  )
}

const FlexBox = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
`

const ImageBox = styled(Box)`
    cursor: pointer;
    width: fit-content;
    height: 50px;
    margin-bottom: 10px;

    & img {
        height: 100%;
    }
`

export default BankCardForm
