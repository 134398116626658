import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

const ConstructorUploadFileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M5.5075 1.6775C6.75685 0.600169 8.3503 0.00517314 10 0C13.3625 0 16.1538 2.5 16.4575 5.72375C18.4475 6.005 20 7.67125 20 9.71625C20 11.9612 18.1275 13.75 15.8588 13.75H12.5C12.3342 13.75 12.1753 13.6842 12.0581 13.5669C11.9408 13.4497 11.875 13.2908 11.875 13.125C11.875 12.9592 11.9408 12.8003 12.0581 12.6831C12.1753 12.5658 12.3342 12.5 12.5 12.5H15.86C17.4737 12.5 18.75 11.235 18.75 9.71625C18.75 8.19625 17.475 6.93125 15.8588 6.93125H15.2338V6.30625C15.235 3.53125 12.91 1.25 10 1.25C8.64985 1.25539 7.34605 1.74304 6.32375 2.625C5.3775 3.44 4.8825 4.4225 4.8825 5.19375V5.75375L4.32625 5.815C2.58 6.00625 1.25 7.44 1.25 9.1475C1.25 10.9812 2.7875 12.5 4.72625 12.5H7.5C7.66576 12.5 7.82473 12.5658 7.94194 12.6831C8.05915 12.8003 8.125 12.9592 8.125 13.125C8.125 13.2908 8.05915 13.4497 7.94194 13.5669C7.82473 13.6842 7.66576 13.75 7.5 13.75H4.72625C2.135 13.75 0 11.7075 0 9.1475C0 6.94375 1.5825 5.11875 3.6775 4.65625C3.85625 3.5775 4.55 2.5025 5.5075 1.6775Z"
        fill="#545AF5"
      />
      <path
        d="M9.5575 19.8175C9.61555 19.8757 9.68452 19.9219 9.76045 19.9534C9.83639 19.9849 9.91779 20.0011 10 20.0011C10.0822 20.0011 10.1636 19.9849 10.2395 19.9534C10.3155 19.9219 10.3844 19.8757 10.4425 19.8175L14.1925 16.0675C14.3099 15.9501 14.3758 15.791 14.3758 15.625C14.3758 15.459 14.3099 15.2999 14.1925 15.1825C14.0751 15.0651 13.916 14.9992 13.75 14.9992C13.584 14.9992 13.4249 15.0651 13.3075 15.1825L10.625 17.8663V6.875C10.625 6.70924 10.5591 6.55027 10.4419 6.43306C10.3247 6.31585 10.1658 6.25 10 6.25C9.83424 6.25 9.67526 6.31585 9.55805 6.43306C9.44084 6.55027 9.375 6.70924 9.375 6.875V17.8663L6.6925 15.1825C6.57514 15.0651 6.41597 14.9992 6.25 14.9992C6.08403 14.9992 5.92485 15.0651 5.8075 15.1825C5.69014 15.2999 5.62421 15.459 5.62421 15.625C5.62421 15.791 5.69014 15.9501 5.8075 16.0675L9.5575 19.8175Z"
        fill="#545AF5"
      />
    </SvgIcon>
  )
}

export default ConstructorUploadFileIcon
