import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import PaymentGraphicTable from 'components/pageClient/applicationForm/2_PaymentGraphic/PaymentGraphicTable'
import { userFlowSelector } from 'redux/reducers/auth/authSelectors'
import { updateAppFormUserFlowThunk } from 'redux/reducers/auth/authThunks'
import {
  appFormInvoiceSelector,
  appFormShopRequestDataSelector,
  appFormTemplateTypeSelector
} from 'redux/reducers/client/allState/clientSelectors'
import {
  getClientContractByAppIdThunk,
  setPaymentGraphicThunk
} from 'redux/reducers/client/allState/clientThunks'
import { constructorActions } from 'redux/reducers/constructors/formConstructor/state/actions'

const PaymentGraphicPage: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { appFormFlowData } = useSelector(userFlowSelector)
  const shopRequestData = useSelector(appFormShopRequestDataSelector)
  const invoice = useSelector(appFormInvoiceSelector)
  const templateType = useSelector(appFormTemplateTypeSelector)

  const [loadInvoice, setLoadInvoice] = useState(false)

  const handleNext = () => {
    const flow_data = appFormFlowData?.flow_data
    if (flow_data) {
      dispatch(updateAppFormUserFlowThunk({ step: flow_data.step + 1 }))
    }
  }

  useEffect(() => {
    const getClientContract = async () => {
      if (appFormFlowData) {
        const {
          flow_data: { id }
        } = appFormFlowData
        if (
          shopRequestData?.credit_policy.credit_type === 'Consumer finance' &&
          templateType === 'entity'
        ) {
          setLoadInvoice(true)
          await dispatch(getClientContractByAppIdThunk(id, 'Invoice'))
          setLoadInvoice(false)
        }
      }
    }

    getClientContract()
  }, [
    appFormFlowData,
    dispatch,
    shopRequestData?.credit_policy.credit_type,
    templateType
  ])

  useEffect(() => {
    dispatch(constructorActions.clientApplication.getClientApplication())
  }, [dispatch])

  useEffect(() => {
    if (shopRequestData) {
      const { loan_term, product_price, credit_policy, category, promo_code } = shopRequestData
      if (category) {
        dispatch(setPaymentGraphicThunk(credit_policy.id, product_price, loan_term, category.id, promo_code))
      }
    }
  }, [appFormFlowData?.flow_data.uuid, dispatch])

  return (
    <>
      <Box mb={2}>
        <PaymentGraphicTable />
        <Box mt={2}>
          {loadInvoice && <CircularProgress />}
          {invoice && (
            <Accordion
              sx={{ width: '100%', boxShadow: 'none !important', border: 'none !important' }}
              square
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ '& .MuiAccordionSummary-content': { justifyContent: 'center' } }}
              >
                <Typography>{t('client.applicationForm.paymentGraphicShowInvoice')}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: '0px !important' }}>
                <Box
                  sx={{
                    width: '100%',
                    '& section': {
                      p: '0px !important',
                      width: '100% !important',
                      boxShadow: 'none !important',
                      overflow: 'auto !important'
                    }
                  }}
                  dangerouslySetInnerHTML={{ __html: invoice?.document }}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      </Box>
      <Button onClick={handleNext} variant="contained">
        {t('common.buttons.next')}
      </Button>
    </>
  )
}

export default PaymentGraphicPage
