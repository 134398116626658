import { createAsyncThunk } from '@reduxjs/toolkit'

import { axiosV2InstancePrivate } from 'api/api'

export const deleteQuery = createAsyncThunk(
  'lender/scoring/deleteQuery',
  async (queryId: number, thunkAPI) => {
    try {
      await axiosV2InstancePrivate
        .delete<void>(`lender/scorecard/queries/${queryId}/`)

      return queryId
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.message)
    }
  })
