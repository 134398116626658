import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ITemplate, ITemplateInput } from 'redux/reducers/constructors/formConstructor/types'
import { ICreditProduct } from 'redux/reducers/lender/allState/types/state'
import { deleteQuery } from 'redux/reducers/lender/scoring/services/deleteQuery'
import { fetchQueries } from 'redux/reducers/lender/scoring/services/fetchQueries'
import { getAllTemplates } from 'redux/reducers/lender/scoring/services/getAllTemplates'
import { getTemplate } from 'redux/reducers/lender/scoring/services/getTemplate'
import { patchQuery } from 'redux/reducers/lender/scoring/services/patchQuery'
import { postQuery } from 'redux/reducers/lender/scoring/services/postQuery'
import { IScoreCardQuery, ScoringQueryModalDataType, ScoringSchema } from 'redux/reducers/lender/scoring/types'

type OpenQueryModalActionType = {
  confirmCallback: (query: ScoringQueryModalDataType) => void
  modalTitle: string
  queryNameFieldText: string
  submitButtonText: string
}

type OpenFeatureEditModalActionType = {
  onConfirm: (value: string) => void
  onClose: () => void
  inputName: string
  template: ITemplate
}

type OpenScoreEditModalActionType = {
  onConfirm: (value: number) => void
  onClose: () => void
}

const initialState: ScoringSchema = {
  queries: [],
  isLoading: false,
  errors: [],
  selectedCreditPolicy: null,
  template: null,
  allTemplates: [],
  queryModal: {
    queryName: '',
    modalTitle: '',
    queryNameFieldText: '',
    submitButtonText: '',
    isOpen: false,
    confirmCallback: null,
    mappingBlock: null,
    inputMapping: null
  },
  featureEditModal: {
    isOpen: false,
    onConfirm: null,
    onClose: null,
    inputMapping: null,
    value: '',
    error: null
  },
  scoreEditModal: {
    isOpen: false,
    onConfirm: null,
    onClose: null,
    value: 0
  }
}

const scoringSlice = createSlice({
  name: 'scoring',
  initialState,
  reducers: {
    setCreditPolicy: (state: ScoringSchema, action: PayloadAction<ICreditProduct | null>) => {
      state.selectedCreditPolicy = action.payload
    },
    setQueries: (state: ScoringSchema, action: PayloadAction<IScoreCardQuery[]>) => {
      state.queries = action.payload
    },
    setTemplate: (state: ScoringSchema, action: PayloadAction<ITemplate | null>) => {
      state.template = action.payload
    },

    // query modal
    openQueryModal: (state: ScoringSchema, action: PayloadAction<OpenQueryModalActionType>) => {
      state.queryModal.isOpen = true
      state.queryModal.confirmCallback = action.payload.confirmCallback
      state.queryModal.modalTitle = action.payload.modalTitle
      state.queryModal.queryNameFieldText = action.payload.queryNameFieldText
      state.queryModal.submitButtonText = action.payload.submitButtonText
    },
    closeQueryModal: (state, action: PayloadAction<void>) => {
      state.queryModal.isOpen = false
      state.queryModal.confirmCallback = null
      state.queryModal.queryName = ''
      state.queryModal.mappingBlock = ''
      state.queryModal.inputMapping = ''
    },
    setQueryModalName: (state, action: PayloadAction<string>) => {
      state.queryModal.queryName = action.payload
    },
    setQueryModalMappingBlock: (state, action: PayloadAction<string | null>) => {
      state.queryModal.mappingBlock = action.payload
    },
    setQueryModalInputMapping: (state, action: PayloadAction<string | null>) => {
      state.queryModal.inputMapping = action.payload
    },
    clearQueryModalData: (state, action: PayloadAction<void>) => {
      state.queryModal.queryName = ''
      state.queryModal.mappingBlock = null
      state.queryModal.inputMapping = null
    },
    // feature modal
    openFeatureModal: (state, action: PayloadAction<OpenFeatureEditModalActionType>) => {
      state.featureEditModal.isOpen = true
      state.featureEditModal.onConfirm = action.payload.onConfirm
      state.featureEditModal.onClose = action.payload.onClose

      // find current input mapping in template by question
      let resInput: ITemplateInput | null = null
      for (const step of action.payload.template.steps) {
        for (const block of step.blocks) {
          for (const input of block.inputs) {
            if (input.name === action.payload.inputName) {
              resInput = input
            }
          }
        }
      }

      state.featureEditModal.inputMapping = resInput
    },
    closeFeatureModal: (state, action: PayloadAction<void>) => {
      state.featureEditModal.isOpen = false
      state.featureEditModal.onConfirm = null
      state.featureEditModal.onClose = null
      state.featureEditModal.inputMapping = null
      state.featureEditModal.value = ''
      state.featureEditModal.error = null
    },
    setFeatureModalValue: (state, action: PayloadAction<string>) => {
      state.featureEditModal.value = action.payload
    },
    // score modal
    openScoreModal: (state, action: PayloadAction<OpenScoreEditModalActionType>) => {
      state.scoreEditModal.isOpen = true
      state.scoreEditModal.onConfirm = action.payload.onConfirm
      state.scoreEditModal.onClose = action.payload.onClose
    },
    closeScoreModal: (state, action: PayloadAction<void>) => {
      state.scoreEditModal.isOpen = false
      state.scoreEditModal.value = 0
      state.scoreEditModal.onConfirm = null
      state.scoreEditModal.onClose = null
    },
    setScoreModalValue: (state, action: PayloadAction<number>) => {
      state.scoreEditModal.value = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // fetchQueries
      .addCase(fetchQueries.pending, (state, action) => {
        state.isLoading = true
        state.queries = []
        state.errors = []
      })
      .addCase(fetchQueries.fulfilled, (state, action) => {
        state.isLoading = false

        if (action.payload) state.queries = action.payload
      })
      .addCase(fetchQueries.rejected, (state, action) => {
        state.isLoading = false
        state.errors = [action.payload]
      })
      // postQuery
      .addCase(postQuery.pending, (state, action) => {
        state.isLoading = true
        state.errors = []
      })
      .addCase(postQuery.fulfilled, (state, action) => {
        state.isLoading = false

        if (action.payload) state.queries.push(action.payload)
      })
      .addCase(postQuery.rejected, (state, action) => {
        state.isLoading = false
        state.errors = [action.payload]
      })
      // deleteQuery
      .addCase(deleteQuery.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(deleteQuery.fulfilled, (state, action) => {
        state.isLoading = false

        const deleteQueryIndex = state.queries.findIndex(query => query.id === action.payload)
        if (deleteQueryIndex !== -1) state.queries.splice(deleteQueryIndex, 1)
      })
      .addCase(deleteQuery.rejected, (state, action) => {
        state.isLoading = false
        state.errors = [action.payload]
      })
      // patchQuery
      .addCase(patchQuery.pending, (state, action) => {
        state.isLoading = true
        state.errors = []
      })
      .addCase(patchQuery.fulfilled, (state, action) => {
        state.isLoading = false

        if (!action.payload) return

        const updatedQueryIndex = state.queries.findIndex(query => query.id === action.payload!.id)
        if (updatedQueryIndex !== -1) state.queries[updatedQueryIndex] = action.payload
      })
      .addCase(patchQuery.rejected, (state, action) => {
        state.isLoading = false
        state.errors = [action.payload]
      })
      // getAllTemplates
      .addCase(getAllTemplates.pending, (state, action) => {
        state.isLoading = true
        state.errors = []
      })
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.isLoading = false
        state.allTemplates = action.payload || []
      })
      .addCase(getAllTemplates.rejected, (state, action) => {
        state.isLoading = false
        state.allTemplates = []
        state.errors = [action.payload]
      })
      // getTemplate
      .addCase(getTemplate.pending, (state, action) => {
        state.isLoading = true
        state.errors = []
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        state.template = action.payload || null
      })
      .addCase(getTemplate.rejected, (state, action) => {
        state.isLoading = false
        state.template = null
        state.errors = [action.payload]
      })
  }
})

export const { actions: scoringActions } = scoringSlice
export const { reducer: scoringReducer } = scoringSlice
