import { createSlice } from '@reduxjs/toolkit'

import { fetchUBKI } from 'redux/reducers/lender/creditBureau/services/fetchUBKI'
import { CreditBureauSchema } from 'redux/reducers/lender/creditBureau/types'

const initialState: CreditBureauSchema = {
  json: null,
  isLoading: false,
  isError: false
}

const creditBureauSlice = createSlice({
  name: 'creditBureau',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUBKI.fulfilled, (state, action) => {
      state.json = action.payload
      state.isLoading = false
    })
    builder.addCase(fetchUBKI.pending, (state, action) => {
      state.json = null
      state.isLoading = true
      state.isError = false
    })
    builder.addCase(fetchUBKI.rejected, (state, action) => {
      state.isError = true
      state.isLoading = false
    })
  }
})

export const { actions: creditBureauActions } = creditBureauSlice
export const { reducer: creditBureauReducer } = creditBureauSlice
