import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getShippingStatuses } from 'redux/reducers/client/productShipping/services/getShipingStatus'
import { ProductShippingModalSchema } from 'redux/reducers/client/productShipping/types'

const initialState: ProductShippingModalSchema = {
  isOpen: false,
  isLoading: false,
  shippingStatuses: []
}

const productShippingSlice = createSlice({
  name: 'client/productShipping',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<void>) => {
      state.isOpen = true
    },
    closeModal: (state, action: PayloadAction<void>) => {
      state.isOpen = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShippingStatuses.pending, (state, action) => {
        state.isLoading = true
        state.shippingStatuses = []
      })
      .addCase(getShippingStatuses.fulfilled, (state, action) => {
        state.isLoading = false
        state.shippingStatuses = action.payload || []
      })
      .addCase(getShippingStatuses.rejected, (state, action) => {
        state.isLoading = false
        state.shippingStatuses = []
      })
  }
})

export const { actions: productShippingActions } = productShippingSlice
export const { reducer: productShippingReducer } = productShippingSlice
