import { Alert, Container, Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'

import { setAppToastMessage } from 'redux/reducers/app/appReducer'
import { appToastMessageSelector } from 'redux/reducers/app/appSelectors'
import { userData } from 'redux/reducers/auth/types/stateTypes'
import { translateType } from 'translations/types'

import HeaderNav from '../common/headerNav/headerNav'

import styles from './HomePage.module.scss'
import LoginPage from './loginPage/loginPage'
import SignUpConfirmationPage from './signUpPage/SignUpConfirmationPage/signUpConfirmationPage'
import SignUpPage from './signUpPage/signUpPage'

const RecoverPasswordPage = React.lazy(() => import('./RecoverPasswordPage/RecoverPasswordPage'))

interface IProps {
  t: translateType
  currentUser: userData | null
}

const HomePage: React.FC<IProps> = ({ t, currentUser }) => {
  const dispatch = useDispatch()
  const toastMessage = useSelector(appToastMessageSelector)

  return (
    <>
      <HeaderNav page="home" />
      <Container className={styles.homePage} maxWidth="sm">
        <Switch>
          <Route path="/login">
            <LoginPage currentUser={currentUser} t={t} />
          </Route>
          <Route path="/signup/confirmation">
            <SignUpConfirmationPage />
          </Route>
          <Route path="/signup">
            <SignUpPage t={t} />
          </Route>
          <Route path="/password_recovery/reset">
            <RecoverPasswordPage t={t} />
          </Route>
        </Switch>
      </Container>
      {toastMessage && (
        <Snackbar
          open={!!toastMessage}
          autoHideDuration={4000}
          onClose={() => dispatch(setAppToastMessage(null))}
        >
          <Alert variant="filled" severity={toastMessage?.type}>
            {toastMessage?.message}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}
export default HomePage
