import { CircularProgress } from '@mui/material'

import cls from './appPreloader.module.scss'

const AppPreloader = () => {
  return (
    <div className={cls.preloader}>
      <CircularProgress size={60}/>
    </div>
  )
}

export default AppPreloader
