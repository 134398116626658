import './translations/i18n'
import './scss/index.scss'
import 'drawflow/dist/drawflow.min.css'

import { ThemeProvider } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import theme from './muiConfig'
import store, { persistor } from './redux/store'

import 'moment/locale/de'

Sentry.init({
  dsn: 'https://820b35faf6694a759125798ceb64af15@o1137655.ingest.sentry.io/4505607584350208',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https:yourserver.io/api/']
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(
  <BrowserRouter>
    <PersistGate persistor={persistor}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </LocalizationProvider>
        </Provider>
      </ThemeProvider>
    </PersistGate>
  </BrowserRouter>,
  document.getElementById('root')
)
