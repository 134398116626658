import { combineReducers } from 'redux'

import lenderReducer from 'redux/reducers/lender/allState/lenderReducer'
import { creditBureauReducer } from 'redux/reducers/lender/creditBureau/creditBureauSlice'
import { productFormReducer } from 'redux/reducers/lender/productForm/productFormSlice'
import { scoringReducer } from 'redux/reducers/lender/scoring/scoringSlice'

const combinedLenderReducer = combineReducers({
  all: lenderReducer,
  scoring: scoringReducer,
  productForm: productFormReducer,
  creditBureau: creditBureauReducer
})

export { combinedLenderReducer as lenderReducer }
