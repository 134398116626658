import { createAsyncThunk } from '@reduxjs/toolkit'

import { axiosInstancePrivate } from 'api/api'
import { ScoringAllTemplatesType } from 'redux/reducers/lender/scoring/types'

export const getAllTemplates = createAsyncThunk(
  'lender/scoring/getAllTemplates',
  async (_: void, thunkAPI) => {
    try {
      const res = await axiosInstancePrivate.get<ScoringAllTemplatesType>('questionnaries/lender/templates-get/')

      if (!res.data) {
        thunkAPI.rejectWithValue('No data')
        return null
      }

      return res.data
    } catch (e: any) {
      thunkAPI.rejectWithValue(e.message)
      return null
    }
  })
