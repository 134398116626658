import { axiosInstance } from 'api/api'
import { getCookie } from 'utils/common/apiHelpers'

export async function fetchBankIdUrl (application_id: number): Promise<{url?: string, error?: string}> {
  try {
    const { data } = await axiosInstance.get('/bankid/auth-request/', {
      params: { application_id },
      headers: {
        Authorization: `Token ${getCookie('token')}`
      }
    })

    if (!data) {
      return { error: 'No data' }
    }

    return { url: JSON.parse(data as string).authorization_link }
  } catch (e: any) {
    return { error: e.message }
  }
}
