import { createAsyncThunk } from '@reduxjs/toolkit'
import i18next from 'i18next'

import { axiosInstancePrivate } from 'api/api'
import { setLenderToastMessage } from 'redux/reducers/lender/allState/lenderReducer'

const parseUbkiData = (data: string) => {
  try {
    const replacedData = data.replace(/'/g, '"')

    return JSON.parse(replacedData)
  } catch (error) {
    console.error('Error parsing JSON:', error)
    return null
  }
}

export const fetchUBKI = createAsyncThunk<string, number, { rejectValue: string }>(
  'lender/creditBureau/fetchUBKI',
  async (appId, thunkAPI) => {
    try {
      const res = await axiosInstancePrivate.get<string>(`/ubki/get_data/${appId}/`)

      if (!res.data) {
        const errText = i18next.t('lender.applications.creditBureau.noDataErrorText')
        thunkAPI.dispatch(setLenderToastMessage({
          message: errText,
          type: 'error'
        }))
        return thunkAPI.rejectWithValue(errText)
      }

      Object.keys(res.data).forEach((item) => {
        // @ts-ignore
        if (typeof res.data[item] === 'string') {
          // @ts-ignore
          const parsedData = parseUbkiData(res.data[item])
          if (parsedData) {
            // @ts-ignore
            res.data[item] = parsedData
          }
        }
      })

      return res.data
    } catch (e: any) {
      const msg: string = e.message

      thunkAPI.dispatch(setLenderToastMessage({
        message: msg,
        type: 'error'
      }))

      return thunkAPI.rejectWithValue(msg)
    }
  })

export const fetchUpdateUBKI = createAsyncThunk<string, number, { rejectValue: string }>(
  'lender/creditBureau/fetchUBKI',
  async (appId, thunkAPI) => {
    try {
      const res = await axiosInstancePrivate.get<string>(`/ubki/get_data/${appId}/?update=true`)

      if (!res.data) {
        const errText = i18next.t('lender.applications.creditBureau.noDataErrorText')
        thunkAPI.dispatch(setLenderToastMessage({
          message: errText,
          type: 'error'
        }))
        return thunkAPI.rejectWithValue(errText)
      }

      Object.keys(res.data).forEach((item) => {
        // @ts-ignore
        if (typeof res.data[item] === 'string') {
          // @ts-ignore
          const parsedData = parseUbkiData(res.data[item])
          if (parsedData) {
            // @ts-ignore
            res.data[item] = parsedData
          }
        }
      })

      return res.data
    } catch (e: any) {
      const msg: string = e.message

      thunkAPI.dispatch(setLenderToastMessage({
        message: msg,
        type: 'error'
      }))

      return thunkAPI.rejectWithValue(msg)
    }
  })
