import {
  FormControl,
  FormLabel,
  SxProps,
  TextField,
  TextFieldProps
} from '@mui/material'
import React, { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

import { ICustomInput } from 'features/formConstructor/types'

interface IProps {
  input: ICustomInput
  styles?: SxProps
  cError?: FieldError | undefined
}

export type TCustomDateInputProps = IProps & TextFieldProps

export const CustomDateInput = forwardRef<HTMLDivElement, TCustomDateInputProps>(
  ({ input, styles, cError, ...props }, ref) => {
    const { question } = input

    return (
      <FormControl fullWidth sx={styles} error={!!cError}>
        <FormLabel>{question}</FormLabel>
        <TextField
          {...props}
          ref={ref}
          sx={{
            border: cError ? '1px solid red' : 'none',
            borderRadius: cError ? '14px' : 'none'
          }}
        />
      </FormControl>
    )
  }
)

CustomDateInput.displayName = 'CustomDateInput'
