import { createSelector } from 'reselect'

import { TState } from 'redux/store'

// LOADING STATUSES <>
export const constructorLoadingStatusSelector = (state: TState) => state.constructors.isLoading
// LOADING STATUSES </>

// CONSTRUCTOR CREATORS <>
export const isOpenNewTemplateCreatorSelector = (state: TState) => state.constructors.creators.template.isNewOpen
export const isOpenStepCreatorSelector = (state: TState) => state.constructors.creators.step
export const isOpenBlockCreatorSelector = (state: TState) => state.constructors.creators.block
export const isOpenInputCreatorSelector = (state: TState) => state.constructors.creators.input
// CONSTRUCTOR CREATORS </>

// CONSTRUCTOR MENU <>
export const constructorMenuIsOpenSelector = (state: TState) => state.constructors.menu.isOpen
export const currentStepIdSelector = (state: TState) => state.constructors.menu.currentStep.id
export const currentStepTabIndexSelector = (state: TState) => state.constructors.menu.currentStep.tabIndex
export const currentBlockIdSelector = (state: TState) => state.constructors.menu.currentBlockId
export const currentMandatoryQuestionIdSelector = (state: TState) => state.constructors.menu.currentMandatoryQuestionId
export const currentInputIdSelector = (state: TState) => state.constructors.menu.currentInputId
export const currentTemplateIdSelector = (state: TState) => state.constructors.menu.currentTemplateId
// CONSTRUCTOR MENU </>

// CONSTRUCTOR TEMPLATES LIST <>
export const constructorTemplatesIdsSelector = (state: TState) => state.constructors.templatesList
// CONSTRUCTOR TEMPLATES LIST </>

// CONSTRUCTOR TEMPLATE <>
export const constructorTemplateSelector = (state: TState) => state.constructors.template
// CONSTRUCTOR TEMPLATE </>

// AVAILABLE CREDIT PRODUCTS <>
export const availableCreditProducts = (state: TState) => state.constructors.availableCreditProducts
// AVAILABLE CREDIT PRODUCTS </>

// CONSTRUCTOR TEMPLATE STEPS <>
export const templateStepsSelector = (state: TState) => state.constructors.template?.steps

export const templateCurrentStepSelector = createSelector(
  [templateStepsSelector, currentStepIdSelector],
  (steps, stepId) => steps?.find(step => step.id === stepId)
)
// CONSTRUCTOR TEMPLATE STEPS </>

// CONSTRUCTOR TEMPLATE BLOCKS <>
export const templateCurrentBlockSelector = createSelector(
  [templateCurrentStepSelector, currentBlockIdSelector],
  (step, blockId) => step?.blocks.find(({ id }) => id === blockId)
)
// CONSTRUCTOR TEMPLATE BLOCKS </>

// CONSTRUCTOR TEMPLATE INPUTS <>
export const templateCurrentInputSelector = createSelector(
  [templateCurrentStepSelector, currentBlockIdSelector, currentInputIdSelector],
  (step, blockId, inputId) => step?.blocks.find(({ id }) => id === blockId)?.inputs.find(({ id }) => id === inputId)
)
// CONSTRUCTOR TEMPLATE INPUTS </>

// CONSTRUCTOR MANDATORY QUESTIONS <>
export const mandatoryQuestionsListSelector = (state: TState) => state.constructors.mandatoryQuestions
// CONSTRUCTOR MANDATORY QUESTIONS </>

// CLIENT APPLICATION <>
export const clientApplicationDataSelector = (state: TState) => state.constructors.clientApplication
// CLIENT APPLICATION </>

// CONSTRUCTOR TEMPLATE </>
