import { SelectChangeEvent } from '@mui/material'
import React from 'react'

export const selectHandler = (event: SelectChangeEvent<any>, setValue: React.Dispatch<React.SetStateAction<any>>) => {
  setValue(event.target.value)
}

export const multipleSelectHandler = (event: SelectChangeEvent<any>, setValue: React.Dispatch<React.SetStateAction<number[]>>) => {
  const { target: { value } } = event
  setValue(typeof value === 'string' ? value.split(',') : value)
}

export const handleFloatInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
  const string = event.currentTarget.value
  event.currentTarget.value = string.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
  if (string[0] === '.') event.currentTarget.value = `0${string}`
  if (string[0] === '0' && string[1] !== '.' && string.length > 1) event.currentTarget.value = string.slice(1)
  if (string[0] === '0' && string.length === 1) event.currentTarget.value = string.replace(/[^0-9.]/g, '')
}

export const getTranslatedName = (item: any, lang: string) => {
  let name = ''
  for (const key in item) {
    if (`name_${lang}` === key) name = item[key]
  }
  return name
}

export const onChangePhone = (e: any, setPhoneMask: React.Dispatch<React.SetStateAction<string>>) => {
  const str = e.target.value
  setPhoneMask(`380 ${str.length >= 3 ? str.slice(4) : str}`)
}
