import React from 'react'

import { IOverlayProps, Overlay } from 'components/common/Overlay/Overlay'
import AppPreloader from 'components/common/preloaders/appPreloader'

interface ILoadingOverlayProps extends Partial<IOverlayProps>{
}

export const LoadingOverlay: React.FC<ILoadingOverlayProps> = (props) => (
  <Overlay {...props}>
    <AppPreloader />
  </Overlay>
)
