import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { getNewDataWithId } from 'features/formConstructor/utils/createDataHelpers'
import { constructorActions } from 'redux/reducers/constructors/formConstructor/state/actions'
import { IFormConstructorState } from 'redux/reducers/constructors/formConstructor/types'

const initialState: IFormConstructorState = {
  creators: {
    template: {
      isNewOpen: false,
      isEditOpen: false
    },
    step: {
      isNewOpen: false,
      isEditOpen: false
    },
    block: {
      isNewOpen: false,
      isEditOpen: false
    },
    input: {
      isNewOpen: false,
      isEditOpen: false
    }
  },
  isLoading: {
    template: false,
    step: false,
    block: false
  },
  menu: {
    isOpen: true,
    currentTemplateId: '',
    currentStep: {
      id: '',
      tabIndex: 0
    },
    currentBlockId: '',
    currentMandatoryQuestionId: '',
    currentInputId: ''
  },
  templatesList: [],
  template: null,
  availableCreditProducts: null,
  clientApplication: {
    isLoading: false,
    currentStepIndex: 0,
    templateBEId: null,
    currentStepData: null,
    mandatoryQuestionsList: null
  },
  mandatoryQuestions: {
    addedMandatoryQuestions: null,
    mandatoryQuestionsList: null
  }
}

const { menu, template, templatesList, creators, mandatoryQuestions, clientApplication } = constructorActions

export const formConstructorSlice = createSlice({
  name: 'formConstructor',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // CREATORS <>
    builder.addCase(creators.template.setIsNewOpen, (state, action) => {
      state.creators.template.isNewOpen = action.payload
    })
    builder.addCase(creators.step.setIsNewOpen, (state, action) => {
      state.creators.step.isNewOpen = action.payload
    })
    builder.addCase(creators.block.setIsNewOpen, (state, action) => {
      state.creators.block.isNewOpen = action.payload
    })
    builder.addCase(creators.input.setIsNewOpen, (state, action) => {
      state.creators.input.isNewOpen = action.payload
    })
    builder.addCase(creators.template.setIsEditOpen, (state, action) => {
      state.creators.template.isEditOpen = action.payload
    })
    builder.addCase(creators.step.setIsEditOpen, (state, action) => {
      state.creators.step.isEditOpen = action.payload
    })
    builder.addCase(creators.block.setIsEditOpen, (state, action) => {
      state.creators.block.isEditOpen = action.payload
    })
    builder.addCase(creators.input.setIsEditOpen, (state, action) => {
      state.creators.input.isEditOpen = action.payload
    })
    // CREATORS </>
    // MENU <>
    builder.addCase(menu.isOpen, (state, action) => {
      state.menu.isOpen = action.payload
    })
    builder.addCase(menu.setCurrentTemplateId, (state, action) => {
      state.menu.currentTemplateId = action.payload
    })
    builder.addCase(menu.setCurrentStep, (state, action) => {
      const id = action.payload
      const tabIndex = state.template?.steps.map(({ id }) => id).indexOf(action.payload)
      state.menu.currentStep = { id, tabIndex: tabIndex || 0 }
      state.menu.currentBlockId = ''
    })
    builder.addCase(menu.setCurrentBlockId, (state, action) => {
      state.menu.currentBlockId = action.payload
    })
    builder.addCase(menu.setCurrentMandatoryQuestionId, (state, action) => {
      state.menu.currentMandatoryQuestionId = action.payload
    })
    builder.addCase(menu.setCurrentInputId, (state, action) => {
      state.menu.currentInputId = action.payload
    })
    // MENU </>
    // TEMPLATE LIST <>
    builder.addCase(templatesList.set, (state, action) => {
      state.templatesList = action.payload
    })
    builder.addCase(templatesList.addTemplate, (state, action) => {
      state.templatesList.push(action.payload)
    })
    // TEMPLATE LIST </>
    // TEMPLATE <>
    builder.addCase(template.setAvailableCreditProducts, (state, { payload }) => {
      state.availableCreditProducts = payload
    })
    builder.addCase(template.set, (state, { payload }) => {
      state.template = payload
      state.menu.currentTemplateId = payload ? payload.id : ''
    })
    // TEMPLATE STEPS <>
    builder.addCase(template.addStep, (state, action) => {
      if (!state.template) return
      const newStep = getNewDataWithId(action.payload)
      newStep.id = 'constructor-' + newStep.id

      state.template.steps.push(newStep)
      state.menu.currentStep = { id: newStep.id, tabIndex: state.template.steps.length - 1 }
    })
    builder.addCase(template.editStep, (state, action) => {
      if (!state.template) return

      const currentStepId = state.menu.currentStep.id
      const indexOfEditableStep = state.template.steps.findIndex(({ id }) => id === currentStepId)
      state.template.steps[indexOfEditableStep] = { ...state.template.steps[indexOfEditableStep], ...action.payload }
    })
    builder.addCase(template.deleteStep, (state, action) => {
      if (!state.template) return

      state.template.steps = state.template.steps.filter(step => step.id !== action.payload)
    })
    // TEMPLATE STEPS </>
    // TEMPLATE BLOCKS <>
    builder.addCase(template.addBlock, (state, action) => {
      if (!state.template) return
      const newBlock = getNewDataWithId(action.payload)
      const currentStepId = state.menu.currentStep.id
      const currentStep = state.template.steps.find(({ id }) => id === currentStepId)
      currentStep?.blocks.push(newBlock)
      state.menu.currentBlockId = newBlock.id
    })
    builder.addCase(template.editBlock, (state, action) => {
      if (!state.template) return

      const currentStepId = state.menu.currentStep.id
      const indexOfEditableStep = state.template.steps.findIndex(({ id }) => id === currentStepId)

      const currentBlockId = state.menu.currentBlockId
      const indexOfEditableBlock = state.template.steps[indexOfEditableStep].blocks.findIndex(({ id }) => id === currentBlockId)

      state.template.steps[indexOfEditableStep].blocks[indexOfEditableBlock] = {
        ...state.template.steps[indexOfEditableStep].blocks[indexOfEditableBlock], ...action.payload
      }
    })
    builder.addCase(template.deleteBlock, (state, { payload }) => {
      if (!state.template) return

      const currentStepId = state.menu.currentStep.id
      const indexOfCurrentStep = state.template.steps.findIndex(({ id }) => id === currentStepId)

      state.template.steps[indexOfCurrentStep].blocks = state.template.steps[indexOfCurrentStep].blocks
        .filter(block => block.id !== payload)
    })
    // TEMPLATE BLOCKS </>
    // TEMPLATE INPUTS <>
    builder.addCase(template.addInput, (state, { payload }) => {
      if (!state.template) return

      const currentStepId = state.menu.currentStep.id
      const currentBlockId = state.menu.currentBlockId
      const currentStep = state.template.steps.find(({ id }) => id === currentStepId)
      const currentBlock = currentStep?.blocks.find(({ id }) => id === currentBlockId)

      if (payload.isMandatoryQuestion !== true) {
        const newInput = getNewDataWithId(payload)

        currentBlock?.inputs.push(newInput)
      } else {
        currentBlock?.inputs.push(payload)
      }
    })

    builder.addCase(template.editInput, (state, { payload }) => {
      if (!state.template) return

      const currentStepId = state.menu.currentStep.id
      const indexOfEditableStep = state.template.steps.findIndex(({ id }) => id === currentStepId)

      const currentBlockId = state.menu.currentBlockId
      const indexOfEditableBlock = state.template.steps[indexOfEditableStep].blocks.findIndex(({ id }) => id === currentBlockId)

      const currentInputId = state.menu.currentInputId
      const indexOfEditableInput = state.template.steps[indexOfEditableStep].blocks[indexOfEditableBlock]
        .inputs.findIndex(({ id }) => id === currentInputId)

      state.template.steps[indexOfEditableStep].blocks[indexOfEditableBlock].inputs[indexOfEditableInput] = {
        ...state.template.steps[indexOfEditableStep].blocks[indexOfEditableBlock].inputs[indexOfEditableInput],
        ...payload
      }
    })
    builder.addCase(template.deleteInput, (state, { payload }) => {
      if (!state.template) return

      const currentStepId = state.menu.currentStep.id
      const indexOfCurrentStep = state.template.steps.findIndex(({ id }) => id === currentStepId)

      const currentBlockId = state.menu.currentBlockId
      const indexOfCurrentBlock = state.template.steps[indexOfCurrentStep].blocks.findIndex(({ id }) => id === currentBlockId)

      state.template.steps[indexOfCurrentStep].blocks[indexOfCurrentBlock].inputs = state.template.steps[indexOfCurrentStep]
        .blocks[indexOfCurrentBlock].inputs.filter(input => input.id !== payload)
    })
    // TEMPLATE INPUTS </>

    // MANDATORY QUESTIONS <>
    builder.addCase(
      mandatoryQuestions.setMandatoryQuestionsList,
      (state, { payload }
      ) => {
        state.mandatoryQuestions.mandatoryQuestionsList = payload
      })
    builder.addCase(
      mandatoryQuestions.setCurrentMandatoryQuestionToList,
      (state, { payload }
      ) => {
        if (!state.mandatoryQuestions.mandatoryQuestionsList) return

        state.mandatoryQuestions.mandatoryQuestionsList = [...state.mandatoryQuestions.mandatoryQuestionsList, Object(payload)]
      })
    builder.addCase(
      mandatoryQuestions.setAddedMandatoryQuestionIdToList,
      (state, { payload }) => {
        if (!state.mandatoryQuestions.addedMandatoryQuestions) {
          state.mandatoryQuestions.addedMandatoryQuestions = [payload]
          return
        }

        state.mandatoryQuestions.addedMandatoryQuestions = [...state.mandatoryQuestions.addedMandatoryQuestions, payload]
      })
    builder.addCase(mandatoryQuestions.setAddedMandatoryQuestionList, (state, { payload }) => {
      state.mandatoryQuestions.addedMandatoryQuestions = payload
    })
    // MANDATORY QUESTIONS </>

    // CLIENT APPLICATION <>
    builder.addCase(clientApplication.getCurrentStep.pending, (state) => {
      state.clientApplication.isLoading = true
    })
    builder.addCase(clientApplication.getCurrentStep.fulfilled, (state) => {
      state.clientApplication.isLoading = false
    })
    builder.addCase(clientApplication.getCurrentStep.rejected, (state) => {
      state.clientApplication.isLoading = false
    })

    builder.addCase(clientApplication.setCurrentStepIndex, (state, { payload }) => {
      state.clientApplication.currentStepIndex = payload
    })
    builder.addCase(clientApplication.setCurrentStepDataToStorage, (state, { payload }) => {
      state.clientApplication.currentStepData = payload
    })
    builder.addCase(clientApplication.setBETemplateID, (state, { payload }) => {
      state.clientApplication.templateBEId = payload
    })
    builder.addCase(clientApplication.setClientsMandatoryQuestionsList, (state, { payload }) => {
      if (!state.clientApplication.mandatoryQuestionsList) {
        state.clientApplication.mandatoryQuestionsList = [payload]
      }

      state.clientApplication.mandatoryQuestionsList = Array.from(new Set([...state.clientApplication.mandatoryQuestionsList, payload]))
    })

    // CLIENT APPLICATION </>

    builder.addMatcher(isAnyOf(
      templatesList.get.pending,
      template.get.pending,
      template.post.pending,
      template.update.pending,
      template.delete.pending
    ), (state) => {
      state.isLoading.template = true
    })
    builder.addMatcher(isAnyOf(
      templatesList.get.fulfilled,
      template.get.fulfilled,
      template.post.fulfilled,
      template.update.fulfilled,
      template.delete.fulfilled
    ), (state) => {
      state.isLoading.template = false
    })
    builder.addMatcher(isAnyOf(
      templatesList.get.rejected,
      template.get.rejected,
      template.post.rejected,
      template.update.rejected,
      template.delete.rejected
    ), (state) => {
      state.isLoading.template = false
    })
    // TEMPLATE </>
  }
})

export default formConstructorSlice.reducer
