import { Box, Button, styled, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import MyModal from 'components/UI/MyModal'
import PaperContainer from 'components/UI/PaperContainer'
import { confirmModalSelector } from 'redux/reducers/common/confirmModal/confirmModalSelectors'
import { confirmModalActions } from 'redux/reducers/common/confirmModal/confirmModalSlice'

const Container = styled(PaperContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`

const ConfirmationModal = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const state = useSelector(confirmModalSelector)

  const handleConfirm = () => {
    state.confirmAction && state.confirmAction()
    dispatch(confirmModalActions.close())
  }

  const handleClose = () => {
    dispatch(confirmModalActions.close())
  }

  return (
    <MyModal isVisible={state.isActive} closeModal={handleClose}>
      <Container>
        <Typography>
          {state.text}
        </Typography>
        <Box>
          <Button
            onClick={handleClose}
          >
            {t('common.confirmation.cancel')}
          </Button>

          <Button
            onClick={handleConfirm}
          >
            {t('common.confirmation.confirm')}
          </Button>
        </Box>
      </Container>
    </MyModal>
  )
}

export default ConfirmationModal
