import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { useParams } from 'react-router-dom'

import { isAuthSelector } from 'redux/reducers/auth/authSelectors'
import { applicationRequest } from 'redux/reducers/client/allState/clientThunks'

interface IParams {
  uuid: string
}

const ClientRedirectPage: React.FC = () => {
  const { uuid } = useParams<IParams>()
  const isAuth = useSelector(isAuthSelector)

  useEffect(() => {
    if (uuid) {
      localStorage.setItem('client_uuid_token', uuid)
    }
  }, [uuid])

  return isAuth ? <Redirect to="/client/application" /> : <Redirect to="/login" />
}

export default connect(null, { applicationRequest })(ClientRedirectPage)
