import { Paper, SxProps } from '@mui/material'
import React from 'react'

interface IProps {
  className?: string
  style?: React.CSSProperties
  children: React.ReactChild | React.ReactChildren | React.ReactNode
  sx?: SxProps
  elevation?: number
}

const PaperContainer: React.FC<IProps> = (props) => {
  const {
    className,
    style,
    children,
    sx,
    elevation
  } = props

  return (
    <Paper
      className={className}
      sx={{
        padding: { xs: '20px 10px', sm: '15px 20px 15px' },
        boxShadow: 'none!important',
        ...sx
      }}
      style={style}
      elevation={elevation}
    >
      {children}
    </Paper>
  )
}

export default PaperContainer
