import { ThunkAction } from 'redux-thunk'

import { IToastMessage } from 'types/common/types'

import { setUserThunk } from '../auth/authThunks'

import appActions from './appActions'
import { actionTypes, appState, ISetAppToastMessage, setInitializedAction } from './appTypes'

const initialState: appState = {
  isInitialized: false,
  toastMessage: null
}

const appReducer = (state = initialState, action: actionTypes): appState => {
  switch (action.type) {
    case appActions.SET_INITIALIZED: {
      return {
        ...state,
        isInitialized: action.payload.isInitialized
      }
    }

    case appActions.SET_APP_TOAST_MESSAGE: {
      return { ...state, toastMessage: action.payload }
    }

    default: return state
  }
}
const setInitialized = (isInitialized: boolean): setInitializedAction => ({ type: appActions.SET_INITIALIZED, payload: { isInitialized } })

type appThunk = ThunkAction<void, appState, unknown, any>

export const setInitializedThunk = (): appThunk => async (dispatch) => {
  await dispatch(setUserThunk())
  dispatch(setInitialized(true))
}

export const setAppToastMessage = (toastMessage: IToastMessage | null): ISetAppToastMessage => ({
  type: appActions.SET_APP_TOAST_MESSAGE,
  payload: toastMessage
})

export default appReducer
