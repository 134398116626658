import { axiosInstance } from 'api/api'
import { getCookie } from 'utils/common/apiHelpers'

export async function fetchBankIdApplicationId (code: string, state: string): Promise<{appId?: number, error?: string}> {
  try {
    const { data } = await axiosInstance.get('/bankid/backend-callback/', {
      params: { code, state },
      headers: {
        Authorization: `Token ${getCookie('token')}`
      }
    })

    if (!data) {
      return { error: 'No data' }
    }

    return { appId: JSON.parse(data as string).application_id }
  } catch (e: any) {
    return { error: e.message }
  }
}
