import { createTheme } from '@mui/material/styles'

import ConstructorSelectArrowIcon from './components/common/icons/ConstructorSelectArrowIcon'

export const mobileMaxWidth = '(max-width: 576px)'

export const applicationFormTheme = createTheme({
  typography: {
    fontFamily: 'Inter',
    h2: {
      fontWeight: 600,
      fontSize: '35px',
      lineHeight: '41px',
      color: '#000000'
    },
    h3: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '23px',
      color: '#FAB92D'
    },
    h4: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#FFFFFF'
    },
    h5: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '21px',
      color: '#000000'
    },
    body1: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#000000'
    }
  },
  spacing: 7.5,
  components: {
    MuiTabs: {
      defaultProps: {
        variant: 'scrollable'
      },
      styleOverrides: {
        root: {
          minHeight: 'auto'
        },
        flexContainer: {
          display: 'inline-flex',
          background: '#A7D2EB',
          borderRadius: '30px'
        },
        indicator: {
          display: 'none'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '8px 15px',
          minHeight: 'auto',
          borderRadius: '30px',
          textTransform: 'capitalize',
          '&.Mui-selected': {
            background: '#6683EA'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          paddingBottom: '7px',
          paddingLeft: '13px',
          color: '#7E7373',
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '600',
          '&.Mui-focused': {
            color: '#7E7373'
          },
          '&.Mui-error': {
            color: '#7E7373'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '14px',
          boxShadow: 'inset 0px 4px 5px rgba(0, 0, 0, 0.15)',
          fontSize: '0.8125rem',
          lineHeight: '0.9375rem',
          color: '#000000',
          background: '#ffffff',
          fontWeight: 500,
          '& fieldset': {
            display: 'none'
          }
        },
        input: {
          padding: '15px'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ConstructorSelectArrowIcon
      },
      styleOverrides: {
        icon: {
          right: '15px'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'small'
      }
    },
    MuiAlert: {
      defaultProps: {
        variant: 'filled'
      }
    }
  }
})
