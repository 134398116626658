import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { INavbarItem, NavbarSchema } from 'redux/reducers/common/navbar/types'

const initialState: NavbarSchema = {
  items: []
}

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<INavbarItem[]>) => {
      state.items = action.payload
    }
  }
})

export const { actions: navbarActions } = navbarSlice
export const { reducer: navbarReducer } = navbarSlice
