import { Paper, Table, TableContainer } from '@mui/material'
import React, { FC } from 'react'

import PaymentGraphicTableBody from './PaymentGraphicTableBody'
import PaymentGraphicTableHead from './PaymentGraphicTableHead'

const PaymentGraphicTable: FC = () => {
  return (
    <TableContainer component={Paper} elevation={5} sx={{ borderRadius: 4 }}>
      <Table sx={{ minWidth: 650 }} id-testing='payment_graphic_table_client_step_1'>
        <PaymentGraphicTableHead />
        <PaymentGraphicTableBody />
      </Table>
    </TableContainer>
  )
}
export default PaymentGraphicTable
